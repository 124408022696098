import React, { useEffect, useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'

import { ConsumedProps, ConsumedType, DurationType, ProjectUsersType } from '@containers/project/components/consumed/consumed-interfaces'
import { OptionsType } from '@interfaces/options'
import { ProjectReport } from '@interfaces/project'
import { round, roundDay } from '@root/services/tools/round'
import { RootState } from '@services/redux/config/reducers'

const Consumed = ({ project, totalConsumedDays, setTotalConsumedDays }: ConsumedProps) => {
  const { data: users } = useSelector((state: RootState) => state.users)
  const { data: types } = useSelector((state: RootState) => state.reportTypes)
  const [disableType, setDisableType] = useState<number[]>([])
  const [totalDuration, setTotalDuration] = useState(0)
  const [currentUser, setCurrentUser] = useState('0')

  // Date Picker state and formatted reports

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [openPicker, setOpenPicker] = useState(false)

  const handleOpenClick = () => {
    setStartDate(new Date())
    setEndDate(new Date())
    setOpenPicker(true)
  }

  const handleCloseClick = () => {
    setStartDate(undefined)
    setEndDate(undefined)
    setOpenPicker(false)
  }

  const formattedReports = useMemo(() => {
    const data: ProjectReport[] = []

    project.reports?.forEach((report) => {
      const reportDate = new Date(report.date).toISOString().split('T')[0]
      const start = startDate?.toISOString().split('T')[0]
      const end = endDate?.toISOString().split('T')[0]

      if (start && end && reportDate >= start && reportDate <= end) {
        data.push(report)
      }
    })

    if (startDate && endDate) {
      return data
    } else {
      return project.reports
    }
  }, [project.reports?.length, startDate, endDate])

  // Array of all users with reports for this project
  const projectUsers = useMemo(() => {
    const data: ProjectUsersType[] = []

    users.forEach((user) => {
      const reports = formattedReports?.filter(report => report.owner === user.id)

      if (reports?.length) {
        data.push({ username: user.username, id: user.id })
      }
    })

    return data
  }, [formattedReports?.length])

  // add options and custom styles for React-Select
  const optionsUsers: OptionsType[] = [{ value: '0', label: 'All users' }]
  projectUsers.forEach((item) => {
    optionsUsers.push({ value: item.id.toString(), label: item.username })
  })

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: 200,
      height: 36,
      minHeight: 36
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0px 4px'
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: 'bold',
      cursor: 'pointer',
      backgroundColor: state.isFocused && 'rgba(55, 65, 81, 1)',
      color: state.isFocused && 'white'
    })
  }

  // Array of all reportTypes with all its reports
  const consumed = useMemo(() => {
    const data: ConsumedType[] = []

    types.forEach((item) => {
      data.push({ type: item.type, id: item.id, reports: [] })
    })

    if (parseInt(currentUser) === 0) {
      formattedReports?.forEach((report) => {
        data.forEach((item) => {
          if (report.reportType === item.id) {
            item.reports.push(report)
          }
        })
      })
    } else {
      const userReports = formattedReports?.filter(report => report.owner === parseInt(currentUser))
      userReports?.forEach((report) => {
        data.forEach((item) => {
          if (report.reportType === item.id) {
            item.reports.push(report)
          }
        })
      })
    }

    return data
  }, [formattedReports?.length, currentUser])

  useEffect(() => {
    setDisableType([])
    setTotalDuration(0)
    consumed.forEach((item) => {
      item.reports.forEach((report) => {
        setTotalDuration(totalDuration => totalDuration + round(report.duration, 2))
      })
    })
  }, [formattedReports?.length, currentUser])

  useEffect(() => {
    setTotalConsumedDays(roundDay(totalDuration, 2))
  }, [totalDuration])

  // Add/Remove reportType id to disableType array and calc totalDuration with/without this reportType
  const disable = (id, reports) => {
    let totalTypeDay = 0
    reports.forEach((report: DurationType) => {
      totalTypeDay = totalTypeDay + round(report.duration, 2)
    })

    if (disableType.includes(id)) {
      setDisableType(disableType.filter(item => item !== id))
      setTotalDuration(totalDuration => totalDuration + totalTypeDay)
    } else {
      setDisableType(oldArray => [...oldArray, id])
      setTotalDuration(totalDuration => totalDuration - totalTypeDay)
    }
  }

  return (
    <div className='relative flex flex-col shadow rounded-r-lg'>
      <div className='absolute flex bottom-full mb-4 justify-between w-full gap-4'>
        {!openPicker && (<button className='mx-auto tilted-red py-2 px-3 rounded-md text-white font-bold' onClick={handleOpenClick}>Choose a period</button>)}

        {openPicker && (
          <>
            <DatePicker
              className='border border-gray-900 p-1 text-center rounded-md w-full'
              dateFormat='dd/MM/yyyy'
              endDate={endDate}
              onChange={(date) => setStartDate(date)}
              selected={startDate}
              selectsStart
              startDate={startDate}
            />

            <button className='tilted-red py-1 px-3 rounded-md font-bold text-white' onClick={handleCloseClick}>X</button>

            <DatePicker
              className='border border-gray-900 p-1 text-center rounded-md w-full'
              dateFormat='dd/MM/yyyy'
              endDate={endDate}
              minDate={startDate}
              onChange={(date) => setEndDate(date)}
              selected={endDate}
              selectsEnd
              startDate={startDate}
            />
          </>
        )}
      </div>

      <div className='tilted-red flex justify-between items-center text-white text-center text-lg font-bold border-b-2 border-gray-400 p-3 uppercase'>
        <div>Consumed</div>

        <Select className='text-base text-gray-900' defaultValue={{ label: 'All users', value: '0' }} onChange={(evt) => setCurrentUser((evt as OptionsType).value)} options={optionsUsers} styles={customStyles} />
      </div>

      <div className='flex flex-col bg-white'>
        {consumed.map((item, index) => {
          let totalDuration = 0
          item.reports.forEach((report) => {
            totalDuration = totalDuration + round(report.duration, 2)
          })
          return (
            <button className={`p-3 flex justify-between hover:bg-gray-200 border-t border-gray-200 font-bold text-base ${disableType.includes(item.id) ? 'text-gray-400 bg-gray-100' : ''}`}
              key={index}
              onClick={() => disable(item.id, item.reports)}
            >
              <div className='uppercase'>
                {item.type}
              </div>

              <div className='w-24 flex justify-end'>
                {`${roundDay(totalDuration, 2)}  days`}
              </div>
            </button>
          )
        })}

        <div className='p-3 flex tilted-red text-white justify-between border-t-2 border-gray-400 font-bold text-lg'>
          <div className='uppercase'>
            Total
          </div>

          <div className='w-32 flex justify-end'>
            {`${totalConsumedDays}  days`}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Consumed
