import { ChevronRightIcon } from '@heroicons/react/solid'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Layout from '@components/layout/layout'
import { Project } from '@interfaces/project'
import { RootState } from '@services/redux/config/reducers'

const Projects = () => {
  const { data: projects } = useSelector((state: RootState) => state.projects)
  return (
    <Layout>
      <div className='py-6'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
          <h1 className='text-2xl text-gray-900 pb-6 text-center uppercase font-bold'>Projects</h1>
        </div>

        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          {/* Replace with your content */}

          <div className='bg-white border border-gray-200 shadow overflow-hidden sm:rounded-md'>
            <ul className='divide-y divide-gray-200' role='listitem'>
              {projects.map((project: Project, index: number) => (
                <li key={`${project.name}-${index}`}>
                  <Link className='block hover:bg-red-800 hover:text-white' to={`/projects/${project.id}`}>
                    <div className='flex items-center px-4 py-4 sm:px-8'>
                      <div className='min-w-0 flex-1 flex items-center'>

                        <div className='min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4'>
                          <div className='flex items-end'>
                            <p className='text-xl font-bold truncate mr-2'>{project.name}</p>

                            {project.customer?.name && <span className='text-xl text-center text-gray-500 font-bold italic'>{`- ${project.customer.name}`}</span>}
                          </div>

                        </div>
                      </div>

                      <div>
                        <ChevronRightIcon aria-hidden='true' className='h-5 w-5 text-gray-900' />
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* /End replace */}
        </div>
      </div>
    </Layout>
  )
}

export default Projects
