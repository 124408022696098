import { call, put } from '@redux-saga/core/effects'
import { toast } from 'react-toastify'

import { logout } from '@containers/login/actions/login-actions'

export const API_URL = process.env.REACT_APP_API_URL ?? 'http://localhost:1337'

export function * apiCallWrapper (fn, ...args) {
  try {
    const response = yield call(fn, ...args)

    const { errors, success } = response

    if (errors) {
      if (errors.statusCode === 401 && localStorage.getItem('jwt')) {
        yield put(logout())
        toast.error('Votre token a expiré ou est invalide')
      }

      return {
        error: errors, // @TODO Extract message id to display error to user
        success
      }
    }

    return {
      ...response,
      success
    }
  } catch (error) {
    return {
      error,
      success: false
    }
  }
}
