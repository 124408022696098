import React, { useState } from 'react'

import { ComparatorProps } from '@containers/project/components/comparator/comparator-interfaces'
import Consumed from '@containers/project/components/consumed/consumed'
import Estimates from '@containers/project/components/estimates/estimates'
import Quotation from '@containers/project/components/quotation/quotation'
import { roundDay } from '@root/services/tools/round'

const Comparator = ({ project }: ComparatorProps) => {
  const [totalEstimateDays, setTotalEstimateDays] = useState(0)
  const [totalConsumedDays, setTotalConsumedDays] = useState(0)
  const [disableQuotation, setDisableQuotation] = useState<number[]>([])
  const noQuotation = 'Without Quotation'

  const percentageConsumed = totalEstimateDays === 0 ? noQuotation : roundDay(((totalConsumedDays * 100) / totalEstimateDays), 1)
  const remainingDays = roundDay((totalEstimateDays - totalConsumedDays), 2)

  return (
    <div className='mt-20 pb-12 sm:pb-16'>
      <div className='relative'>

        <div className='relative max-w-7xl mx-auto'>
          <div className='mx-auto'>
            <div className='rounded-lg grid lg:grid-cols-2 relative lg:gap-96'>

              <Estimates disableQuotation={disableQuotation} project={project} setTotalEstimateDays={setTotalEstimateDays} totalEstimateDays={totalEstimateDays} />

              <div className='flex flex-col text-center items-center relative my-12 lg:absolute lg:justify-self-center lg:self-center'>
                <div className={`rounded-full h-48 w-48 flex flex-col justify-center items-center font-bold text-3xl text-white ${percentageConsumed >= 100 ? 'bg-red-600' : 'bg-green-600'}`}>
                  {percentageConsumed === noQuotation ? percentageConsumed : `${percentageConsumed} %`}

                  {percentageConsumed !== noQuotation && <div className='text-2xl'>Consumed</div>}
                </div>

                {remainingDays > 0 && <div className='font-bold text-xl absolute -bottom-8 lg:-bottom-12'>
                  {`${remainingDays} days left`}
                </div>}
              </div>

              <Consumed project={project} setTotalConsumedDays={setTotalConsumedDays} totalConsumedDays={totalConsumedDays} />
            </div>

            {project.quotations?.length ? <Quotation disableQuotation={disableQuotation} project={project} setDisableQuotation={setDisableQuotation} /> : null }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Comparator
