import moment from 'moment'
import React from 'react'

import { TeamHeader } from '@interfaces/header'

interface Props {
  headers: TeamHeader[]
}

function TeamHeaders ({ headers }: Props) {
  const classNameManagement = (index) => {
    if (index === 14) {
      return 'rounded-tr-xl'
    } else if (index === 0) {
      return 'rounded-tl-xl border-r border-white'
    } else {
      return 'border-white border-r'
    }
  }

  const today = moment().format('MMM DD')

  return (
    <thead className='sticky top-0'>
      <tr className='sticky top-0'>
        {headers.map((item, index) => {
          const day = item?.day
          const date = item?.date

          return (
            <th
              className={
                `text-center w-20 border-solid sticky top-0 px-2 whitespace-nowrap
                 tracking-wider font-bold ${classNameManagement(index)}
                 ${today === date ? 'bg-white text-red-800' : 'tilted-red text-white'}
                 ${index === 0 ? 'left-0 z-10' : ''}`
              }
              key={`${index}`}
            >
              {day}

              {day ? <br /> : null}

              {date}

              {!!item?.day && <br />}

              {index === 0 && 'User'}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default TeamHeaders
