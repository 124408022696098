import axios, { AxiosTransformer, Method } from 'axios'

import { API_URL } from '@services/api'
import { LOGIN_URL } from '@services/api/auth'
import { keysToCamel } from '@services/tools/string'

interface RequestErrorMessage {
  id: string
  message: string
}

interface RequestError {
  messages: RequestErrorMessage[]
}

interface ResponseType<DATA> {
  data: DATA
  errors?: RequestError[]
  errorCode?: string
  success: boolean
}

interface Headers {
  [anyAttribute: string]: string
}

interface AXIOS_REQUEST {
  baseURL?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
  headers?: Headers
  method?: Method
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any
  timeout?: number
  url: string
}

export const setAuthorizationToken = (token?: string) => {
  if (token) {
    localStorage.setItem('jwt', token)
  } else {
    localStorage.removeItem('jwt')
  }
}

const defaultTransformers = (): AxiosTransformer[] => {
  const { transformRequest } = axios.defaults

  if (!transformRequest) {
    return []
  } else if (transformRequest instanceof Array) {
    return transformRequest
  } else {
    return [transformRequest]
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
const axiosResponseTransformer = (data?: object | string) => {
  if (data) {
    return keysToCamel(typeof data === 'string' ? JSON.parse(data) : data)
  }

  return data
}

const axiosRequest = async <DATA>({
  baseURL = API_URL,
  data,
  headers = {},
  method = 'POST',
  params,
  timeout = 10000,
  url
}: AXIOS_REQUEST): Promise<ResponseType<DATA>> => {
  return await new Promise((resolve, reject) => {
    const authorizationToken = localStorage.getItem('jwt')

    if (authorizationToken && url !== LOGIN_URL) {
      headers.authorization = `Bearer ${authorizationToken}`
    }

    axios({
      baseURL,
      data,
      params,
      headers,
      method,
      timeout,
      transformResponse: [...defaultTransformers(), axiosResponseTransformer],
      url
    }).then(
      ({ data }) => {
        resolve({
          data: typeof data === 'string' ? JSON.parse(data) : data,
          success: true
        })
      },
      (payload) => {
        // eslint-disable-next-line no-console

        if (payload.response) {
          const { response } = payload

          if (response.status >= 400 && response.status < 500) {
            resolve({
              data: response.data,
              errors: response.data,
              success: false
            })
          } else {
            reject(response)
          }
        } else {
          reject(payload)
        }
      }
    )
  })
}

export const axiosDelete = async <RESPONSE_DATA>(
  url: string,
  timeout?: number
) =>
  await axiosRequest<RESPONSE_DATA>({
    method: 'DELETE',
    timeout,
    url
  })

export const axiosPut = async <BODY, RESPONSE_DATA>(
  url: string,
  body: BODY,
  timeout?: number
) =>
  await axiosRequest<RESPONSE_DATA>({
    data: body,
    method: 'PUT',
    timeout,
    url
  })

export const axiosPost = async <BODY, RESPONSE_DATA>(
  url: string,
  body: BODY,
  timeout?: number
) =>
  await axiosRequest<RESPONSE_DATA>({
    data: body,
    method: 'POST',
    timeout,
    url
  })

export const axiosGet = async <QUERY_PARAMS, RESPONSE_DATA>(
  url: string,
  params: QUERY_PARAMS,
  timeout?: number
) =>
  await axiosRequest<RESPONSE_DATA>({
    data: params,
    method: 'GET',
    timeout,
    url
  })
