import { call, put, takeLatest } from 'redux-saga/effects'

import { apiCallWrapper } from '@services/api'
import { axiosGet } from '@services/api/axios'
import { TYPES_URL } from '@services/api/timesheet'
import {
  listReportTypesFailure,
  listReportTypesSuccess,
  REPORT_TYPES_ACTION_TYPES
} from '@services/redux/report-types/actions'

function * listReportTypes () {
  try {
    const { data, error, success } = yield call(
      apiCallWrapper,
      axiosGet,
      TYPES_URL
    )

    if (success) {
      yield put(listReportTypesSuccess(data))
    } else {
      yield put(listReportTypesFailure(error))
    }
  } catch (error) {
    yield put(listReportTypesFailure(error))
  }
}

export default function * reportTypesSaga () {
  yield takeLatest(REPORT_TYPES_ACTION_TYPES.LIST_REPORT_TYPES, listReportTypes)
}
