import React, { MouseEventHandler } from 'react'

interface Props {
  onBackPressed: MouseEventHandler<HTMLButtonElement>
  onNextPressed: MouseEventHandler<HTMLButtonElement>
}

const NextBackButtons = ({ onBackPressed, onNextPressed }: Props) => {
  return (
    <div className='px-2 sm:px-4 md:px-6 mb-4'>
      <button
        className='rounded ml-4 mr-2 bg-gray-700 hover:bg-white hover:text-gray-700 border-2 border-transparent hover:border-gray-700 text-white font-bold py-2 px-6 text-sm'
        id='back-button'
        onClick={onBackPressed}
        type='button'
      >
        Back
      </button>

      <button
        className='rounded bg-gray-700 hover:bg-white hover:text-gray-700 border-2 border-transparent hover:border-gray-700 text-white font-bold py-2 px-6 text-sm'
        id='next-button'
        onClick={onNextPressed}
        type='button'
      >
        Next
      </button>
    </div>
  )
}

export default NextBackButtons
