import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Layout from '@components/layout/layout'
import Comparator from '@containers/project/components/comparator/comparator'
import { ParamsId } from '@containers/project/project-interfaces'
import { listProjects } from '@root/services/redux/projects/actions'
import { RootState } from '@services/redux/config/reducers'

const Project = () => {
  const { data: projects } = useSelector((state: RootState) => state.projects)
  const { id } = useParams<ParamsId>()
  const project = projects.find(review => review.id.toString() === id)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listProjects())
  }, [])

  return (
    <Layout>
      <div className='py-6'>
        <div className='lg:absolute max-w-7xl mx-auto lg:top-16 inset-x-0 px-4 sm:px-6 lg:px-8'>
          {project.customer?.name && <div className='text-lg text-center text-gray-500 font-bold italic'>{project.customer.name}</div>}

          <h1 className='text-2xl text-gray-900 text-center uppercase font-bold'>{project.name}</h1>
        </div>

        <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          {/* Replace with your content */}

          <Comparator project={project} />

          {/* /End replace */}
        </div>
      </div>
    </Layout>
  )
}

export default Project
