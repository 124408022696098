import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { DayType, EstimatesProps, EstimatesType } from '@containers/project/components/estimates/estimates-interfaces'
import { RootState } from '@services/redux/config/reducers'

const Estimates = ({ project, disableQuotation, totalEstimateDays, setTotalEstimateDays }: EstimatesProps) => {
  const { data: types } = useSelector((state: RootState) => state.reportTypes)
  const [disableType, setDisableType] = useState<number[]>([])

  // Array of all reportTypes with total of days for each quotations
  const estimates = useMemo(() => {
    const data: EstimatesType[] = []
    types.forEach((item) => {
      data.push({ type: item.type, id: item.id, days: [] })
      project.quotations?.forEach((quotation) => {
        if (!disableQuotation.includes(quotation.id)) {
          const prices = quotation.prices?.find(prices => prices.reportType.id === item.id)
          if (prices) {
            prices.quotationId = quotation.id
          }
          data.forEach((day) => {
            if (day.id === prices?.reportType.id) {
              day.days.push(prices)
            }
          })
        }
      })
    })

    return data
  }, [project?.quotations?.length, disableQuotation])

  useEffect(() => {
    setTotalEstimateDays(0)
    estimates.forEach((item, index) => {
      item.days.forEach((day) => {
        setTotalEstimateDays(totalEstimateDays => totalEstimateDays + day.day)
      })
    })
  }, [project.quotations?.length])

  // Reset DisableType and TotalEstimateDays everytime disableQuotation is update to recalculated TotalEstimateDays
  useEffect(() => {
    setTotalEstimateDays(0)
    setDisableType([])
    estimates.forEach((item, index) => {
      item.days.forEach((day) => {
        if (!disableQuotation.includes(day.quotationId)) {
          setTotalEstimateDays(totalEstimateDays => totalEstimateDays + day.day)
        }
      })
    })
  }, [disableQuotation])

  // Add/Remove reportType id to disableType array and calc totalEstimateDays with/without this reportType
  const disable = (id, days) => {
    let totalTypeDay = 0
    days.forEach((day: DayType) => {
      totalTypeDay = totalTypeDay + day.day
    })

    if (disableType.includes(id)) {
      setDisableType(disableType.filter(item => item !== id))
      setTotalEstimateDays(totalEstimateDays => totalEstimateDays + totalTypeDay)
    } else {
      setDisableType(oldArray => [...oldArray, id])
      setTotalEstimateDays(totalEstimateDays => totalEstimateDays - totalTypeDay)
    }
  }

  return (
    <div className='flex flex-col shadow rounded-l-lg overflow-hidden'>
      <div className='tilted-red text-white text-center text-lg font-bold border-b-2 border-gray-400 p-4 uppercase'>Estimates</div>

      <div className='flex flex-col bg-white'>
        {estimates.map((item, index) => {
          let totalDay = 0
          item.days.forEach((day) => {
            totalDay = totalDay + day.day
          })
          return (
            <button className={`p-3 flex justify-between hover:bg-gray-200 border-t border-gray-200 font-bold text-base ${disableType.includes(item.id) ? 'text-gray-400 bg-gray-100' : ''}`}
              key={index}
              onClick={() => disable(item.id, item.days)}
            >
              <div className='uppercase'>
                {item.type}
              </div>

              <div className='w-24 flex justify-end'>
                {`${totalDay}  days`}
              </div>
            </button>
          )
        })}

        <div className='p-3 tilted-red text-white flex justify-between border-t-2 border-gray-400 font-bold text-lg'>
          <div className='uppercase'>
            Total
          </div>

          <div className='w-32 flex justify-end'>
            {`${totalEstimateDays}  days`}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Estimates
