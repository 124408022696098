import { Customer } from '@interfaces/customer'
import { CUSTOMERS_ACTION_TYPES } from '@services/redux/customers/actions'

interface CustomersState {
  data: Customer[]
  error?: Error
  isFetching: boolean
}

const initialState: CustomersState = {
  data: [],
  isFetching: false
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMERS_ACTION_TYPES.LIST_CUSTOMERS:
      return {
        ...state,
        error: undefined,
        isFetching: true
      }

    case CUSTOMERS_ACTION_TYPES.LIST_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.customers
      }

    case CUSTOMERS_ACTION_TYPES.LIST_CUSTOMERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }

    default:
      return state
  }
}

export default customersReducer
