import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import React, { Fragment, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { deleteReportAction } from '@containers/timesheet/actions/timesheet-actions'
import { DateTimeString } from '@interfaces/date'
import { Report } from '@interfaces/report'

interface Props {
  start: DateTimeString
  end: DateTimeString
  closeModal: () => void
  reports: Report[] | undefined
}

export default function ModalDelete ({ start, end, closeModal, reports }: Props) {
  const [open, setOpen] = useState(true)
  const dispatch = useDispatch()
  const cancelButtonRef = useRef(null)

  const handleClick = () => {
    setOpen(false)
    if (reports !== undefined) {
      dispatch(deleteReportAction(reports.map(report => report.id), start, end))
    }
    closeModal()
  }

  const cancelDelete = () => {
    setOpen(false)
    closeModal()
  }
  return (
    <Transition.Root as={Fragment} show={open}>
      <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <span aria-hidden='true' className='hidden sm:inline-block sm:align-middle sm:h-screen'>
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
              <div className='sm:flex sm:items-start'>
                <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                  <ExclamationIcon aria-hidden='true' className='h-6 w-6 text-red-600' />
                </div>

                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                  <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                    Delete report
                  </Dialog.Title>

                  <div className='mt-2'>
                    <p className='text-sm text-gray-500'>
                      Are you sure about deleting this report ?
                    </p>
                  </div>
                </div>

              </div>

              <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                <button
                  className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
                  onClick={handleClick}
                  type='button'
                >
                  Confirm
                </button>

                <button
                  className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:w-auto sm:text-sm'
                  onClick={cancelDelete}
                  ref={cancelButtonRef}
                  type='button'
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
