import 'react-toastify/dist/ReactToastify.css'

import React from 'react'
import { useSelector } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import PrivateRoute from '@components/routes/privatesRoutes'
import Login from '@containers/login/login'
import Project from '@containers/project/project'
import Projects from '@containers/projects/projects'
import Timesheet from '@containers/timesheet/timesheet'
import { RootState } from '@services/redux/config/reducers'

const App = () => {
  const { isLogged } = useSelector((state: RootState) => state.user)

  return (
    <div className='App'>
      <Router>
        <Switch>
          <Route component={Login} exact path='/login' />

          <PrivateRoute isLogged={isLogged} path='/projects/:id'>
            <Project />
          </PrivateRoute>

          <PrivateRoute isLogged={isLogged} path='/projects'>
            <Projects />
          </PrivateRoute>

          <PrivateRoute isLogged={isLogged} path='/'>
            <Timesheet />
          </PrivateRoute>

        </Switch>
      </Router>

      <ToastContainer />
    </div>
  )
}

export default App
