import { TrashIcon } from '@heroicons/react/outline'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'

import { postTaskAction } from '@containers/timesheet/actions/timesheet-actions'
import { DateTimeString } from '@interfaces/date'
import { OptionsType } from '@interfaces/options'
import { RootState } from '@services/redux/config/reducers'
import { formatReportLines } from '@services/tools/reports/formatter'

interface ReportProps {
  dateArray: moment.Moment[]
  start: DateTimeString
  end: DateTimeString
  removeAddReportLine: () => void
}

const AddReport = ({ dateArray, start, end, removeAddReportLine }: ReportProps) => {
  const { data: projects } = useSelector((state: RootState) => state.projects)
  const { data: types } = useSelector((state: RootState) => state.reportTypes)
  const { data: quotations } = useSelector((state: RootState) => state.quotations)
  const [projectName, setProjectName] = useState<string>()
  const [reportTypeName, setTypeName] = useState<string>()
  const [quotationName, setQuotationName] = useState<string>()
  const [duration, setDuration] = useState('')
  const dispatch = useDispatch()
  const [data, setData] = useState<string | number>('')
  const { data: reports } = useSelector((state: RootState) => state.reports)
  const reportLines = useMemo(() => formatReportLines(reports), [reports])

  // Waiting for a fix from the lib
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectQuotationRef = useRef<any>()

  const handleChange = (v) => {
    setData(parseFloat(v.value) * 100)
  }

  const currencyFormatter = (data: string) => {
    if (!Number(data)) {
      return '__:__'
    }
    if (data.length === 1) {
      return `__:_${data}`
    } else if (data.length === 2) {
      return `__:${data}`
    } else if (data.length === 3) {
      return `_${data[0]}:${data[1]}${data[2]}`
    } else if (data.length === 4) {
      return `${data[0]}${data[1]}:${data[2]}${data[3]}`
    }
    return ''
  }
  const toMinutes = (mins) => {
    const hoursDotMinutes = mins
    const fieldArray = hoursDotMinutes.split(':')
    const firstPart = fieldArray[1].split('_').join('0')
    const secondPart = fieldArray[0].split('_').join('0')
    const minutes = parseInt(secondPart) * 60 + parseInt(firstPart)
    return minutes
  }

  const handleNewReport = (date) => {
    const projectId = projects.find(review => review.name === projectName)
    let quotationId = quotations?.find(review => review.name === quotationName)
    quotationId = typeof quotationId === 'undefined' ? null : quotationId

    const durationInt = toMinutes(duration)

    if (durationInt && !isNaN(durationInt)) {
      const reportTypeId = types.find(review => review.type === reportTypeName)

      if (projectId && reportTypeId) {
        dispatch(postTaskAction({
          reportTypeId,
          projectId,
          quotationId,
          date: date.format('YYYY-MM-DD'),
          duration: durationInt,
          projectName,
          reportTypeName,
          quotationName,
          start,
          end
        }))
      }
    }
    removeAddReportLine()
  }

  // custom styles and options for react-select
  const optionsProjects: OptionsType[] = []
  projects.forEach((item) => {
    optionsProjects.push({ value: item.name, label: item.name })
  })

  const optionsTypes: OptionsType[] = []
  types.forEach((item) => {
    optionsTypes.push({ value: item.type, label: item.type })
  })

  // Set quotations options related to projectName selected
  const optionsQuotations = useMemo(() => {
    const data: OptionsType[] = [{ value: 'none', label: 'none' }]

    const project = projects.find(project => project.name === projectName)

    project?.quotations?.forEach((quotation) => {
      data.push({ value: quotation.name, label: quotation.name })
    })

    return data
  }, [projectName])

  useEffect(() => {
    selectQuotationRef?.current?.setValue({ value: 'none', label: 'none' })
  }, [projectName])

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: 'none',
      border: 'none',
      height: 54,
      backgroundColor: 'transparent'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: 0
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: 'bold',
      cursor: 'pointer',
      backgroundColor: state.isFocused && 'rgba(55, 65, 81, 1)',
      color: state.isFocused && 'white'
    }),
    menuList: (base) => ({
      ...base,

      '::-webkit-scrollbar': {
        width: '5px',
        height: '0px'
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888'
      }
    })
  }

  const classNameManagement = (item, index) => {
    const today = moment().format('MM-DD')

    if (item.format('MM-DD') === today) {
      return 'bg-indigo-100 border-white'
    } else if (index > 4 && index < 9) {
      return 'bg-gray-200 border-white'
    } else {
      return ''
    }
  }

  const valueManagement = (data, value) => {
    reportLines.forEach((item) => {
      const report = item.reports.find(report => moment(report.date).diff(value, 'days') === 0)
      return ((report) ? Number(data) / 100 : null)
    })
    return null
  }

  return (
    <tr className='bg-gray-100'>
      <td className='text-center px-1 border-t border-r border-solid font-mono'>
        <Select menuPortalTarget={document.querySelector('body')}
          onChange={(evt) => setProjectName((evt as OptionsType).value)}
          options={optionsProjects}
          styles={customStyles}
        />
      </td>

      <td className='px-1 text-center border-t border-r border-solid font-mono'>
        <Select menuPortalTarget={document.querySelector('body')}
          onChange={(evt) => setTypeName((evt as OptionsType).value)}
          options={optionsTypes}
          styles={customStyles}
        />
      </td>

      <td className='px-1 text-center border-t border-r border-solid font-mono'>
        <Select defaultValue={{ label: 'none', value: 'none' }}
          menuPortalTarget={document.querySelector('body')}
          onChange={(evt) => setQuotationName((evt as OptionsType).value)}
          options={optionsQuotations}
          ref={selectQuotationRef}
          styles={customStyles}
        />
      </td>

      {dateArray.map((item, index) => {
        return (
          <td className={`border-solid border-t border-l hover:bg-gray-300 mt-4 ${classNameManagement(item, index)}`} key={item.format('YYYY-MM-DD')}>
            <NumberFormat
              allowEmptyFormatting
              allowLeadingZeros={true}
              allowNegative={false}
              className='bg-transparent h-10 w-full text-center placeholder-gray-500 focus:outline-none placeholder-opacity-80 font-mono'
              decimalSeparator=''
              format={currencyFormatter}
              isNumericString={false}
              mask='_'
              onBlur={(duration) ? () => handleNewReport(item) : () => null}
              onChange={(e) => setDuration(e.target.value)}
              onValueChange={handleChange}
              prefix=''
              thousandSeparator={false}
              thousandsGroupStyle='thousand'
              value={valueManagement(data, item)}
            />

          </td>
        )
      })}

      <td className='border-b border-solid h-9 text-center border-r'>-</td>

      <td className='group text-center border-solid border-b hover:bg-gray-100 font-mono tilted-red sticky right-0'>
        <button className='w-full h-full py-3 px-1' onClick={() => removeAddReportLine()}>
          <TrashIcon className='inline-block h-7 w-7 rounded-full text-white group-hover:text-gray-700' />
        </button>
      </td>
    </tr>
  )
}

export default AddReport
