import { call, put, takeLatest } from 'redux-saga/effects'

import { apiCallWrapper } from '@services/api'
import { axiosGet } from '@services/api/axios'
import { PROJECTS_URL } from '@services/api/timesheet'
import {
  listProjectsFailure,
  listProjectsSuccess,
  PROJECTS_ACTION_TYPES
} from '@services/redux/projects/actions'

function * listProjects () {
  try {
    const { data, error, success } = yield call(
      apiCallWrapper,
      axiosGet,
      PROJECTS_URL
    )

    if (success) {
      yield put(listProjectsSuccess(data))
    } else {
      yield put(listProjectsFailure(error))
    }
  } catch (error) {
    yield put(listProjectsFailure(error))
  }
}

export default function * projectsSaga () {
  yield takeLatest(PROJECTS_ACTION_TYPES.LIST_PROJECTS, listProjects)
}
