import { Report } from '@interfaces/report'

export enum REPORTS_LAST_WEEK_ACTION_TYPES {
  LIST_LAST_WEEK_REPORTS = 'LIST_LAST_WEEK_REPORTS',
  LIST_LAST_WEEK_REPORTS_SUCCESS = 'LIST_LAST_WEEK_REPORTS_SUCCESS',
  LIST_LAST_WEEK_REPORTS_FAILURE = 'LIST_LAST_WEEK_REPORTS_FAILURE'
}

export const listLastWeekReports = (start: string, end: string) => ({
  type: REPORTS_LAST_WEEK_ACTION_TYPES.LIST_LAST_WEEK_REPORTS,
  payload: {
    start: start,
    end: end
  }
})

export const listLastWeekReportsSuccess = (reports: Report[]) => ({
  reports,
  type: REPORTS_LAST_WEEK_ACTION_TYPES.LIST_LAST_WEEK_REPORTS_SUCCESS
})

export const listLastWeekReportsFailure = (error: Error) => ({
  error,
  type: REPORTS_LAST_WEEK_ACTION_TYPES.LIST_LAST_WEEK_REPORTS_FAILURE
})

export type listLastWeekReportsType = ReturnType<typeof listLastWeekReports>
export type listLastWeekReportsSuccessType = ReturnType<typeof listLastWeekReportsSuccess>
export type listLastWeekReportsFailureType = ReturnType<typeof listLastWeekReportsFailure>
