import { User } from '@interfaces/user'

export enum USER_LIST_ACTION_TYPES {
  GET_USER_LIST = 'GET_USER_LIST',
  GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS',
  GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE'
}

export const getUserList = () => ({
  type: USER_LIST_ACTION_TYPES.GET_USER_LIST
})

export const getUserListSuccess = (users: User[]) => ({
  users,
  type: USER_LIST_ACTION_TYPES.GET_USER_LIST_SUCCESS
})

export const getUserListFailure = (error: Error) => ({
  error,
  type: USER_LIST_ACTION_TYPES.GET_USER_LIST_FAILURE
})

export type getUserListType = ReturnType<typeof getUserList>
export type getUserListSuccessType = ReturnType<typeof getUserListSuccess>
export type getUserListFailureType = ReturnType<typeof getUserListFailure>

export type UserListActionTypes =
  getUserListType
  | getUserListFailureType
  | getUserListSuccessType
