import { TIMESHEET_TYPES } from '@containers/timesheet/actions/timesheet-actions'
import { Project } from '@interfaces/project'
import { PROJECTS_ACTION_TYPES } from '@services/redux/projects/actions'

interface ProjectsState {
  data: Project[]
  error?: Error
  isFetching: boolean
}

const initialState: ProjectsState = {
  data: [],
  isFetching: false
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECTS_ACTION_TYPES.LIST_PROJECTS:
      return {
        ...state,
        error: undefined,
        isFetching: true
      }

    case PROJECTS_ACTION_TYPES.LIST_PROJECTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.projects
      }

    case PROJECTS_ACTION_TYPES.LIST_PROJECTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }

    case TIMESHEET_TYPES.CREATE_PROJECT:
      return {
        ...state,
        data: [
          ...state.data,
          {
            name: action.payload.newProjectName,
            customer: action.payload.customerId
          }
        ]
      }

    default:
      return state
  }
}

export default projectsReducer
