import React, { ReactNode } from 'react'

import Navigation from '@components/navigation/navigation'
interface Props {
  children: ReactNode
}

const Layout = ({ children }: Props) => {
  return (
    <>
      <Navigation />
      <div className='h-full flex'>
        <div className='flex flex-col w-0 flex-1'>
          <main className='flex-1 relative z-0 focus:outline-none'>
            {children}
          </main>
        </div>
      </div>
    </>
  )
}

export default Layout
