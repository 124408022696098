import { XIcon } from '@heroicons/react/outline'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { QuotationProps } from '@containers/project/components/quotation/quotation-interfaces'
import { ProjectQuotations } from '@interfaces/quotation'
import { ReportType } from '@interfaces/report-type'
import { RootState } from '@services/redux/config/reducers'

const Quotation = ({ project, disableQuotation, setDisableQuotation }: QuotationProps) => {
  const { data: types } = useSelector((state: RootState) => state.reportTypes)
  const [disableAll, setDisableAll] = useState(false)

  const gridCols: number = parseInt(types.length) + 1

  // Array of all quotations for the current project
  const quotations = useMemo(() => {
    const data: ProjectQuotations[] = []
    project.quotations?.forEach((item) => {
      const invoicedState = item?.isInvoiced ?? false
      data.push({ name: item.name, id: item.id, invoiced: invoicedState, types: [] })
    })

    types.forEach((type: ReportType) => {
      data.forEach((quotation) => {
        quotation.types.push({ type: type.type, id: type.id, day: 0 })
      })
    })

    data.forEach((data) => {
      const quotation = project.quotations?.find(quotation => quotation.id === data.id)
      quotation?.prices?.forEach((price) => {
        const type = data.types.find(type => type.id === price.reportType.id)
        if (type) {
          type.day = price.day
        }
      })
    })

    return data
  }, [project?.quotations?.length])

  // Disable quotation with invoiced state at true
  useEffect(() => {
    quotations.forEach((quotation) => {
      if (quotation.invoiced) {
        setDisableQuotation(oldArray => [...oldArray, quotation.id])
      }
    })
  }, [])

  // Add/Remove quotation id to disableQuotation array
  const disable = (id) => {
    if (disableQuotation.includes(id)) {
      setDisableQuotation(disableQuotation.filter(item => item !== id))
    } else {
      setDisableQuotation(oldArray => [...oldArray, id])
    }
  }

  const toggleAll = () => {
    if (!disableAll) {
      quotations.forEach((quotation) => {
        setDisableQuotation(oldArray => [...oldArray, quotation.id])
      })
    } else {
      setDisableQuotation([])
    }
    setDisableAll(!disableAll)
  }

  return (
    <div className='rounded-lg flex flex-col overflow-hidden mt-16 shadow'>
      <div className='tilted-red flex uppercase text-white text-center text-sm lg:text-base font-bold w-full'>
        <div className='p-2 w-40 lg:w-60 flex-none relative'>
          Quotation
          <button className={`w-5 lg:w-6 absolute left-2 rounded border-2 border-gray-700 ${disableAll ? 'bg-white' : 'bg-gray-700'}`} onClick={toggleAll}>
            <XIcon className={`text-white ${disableAll ? 'text-gray-700' : ''}`} />
          </button>
        </div>

        <div className={`grid grid-cols-${gridCols} w-full`}>

          {types.map((type: ReportType) => {
            return (
              <div className='border-l border-white p-2 truncate' key={`${type.type}-${type.id}`}>{type.type}</div>
            )
          })}

          <div className='border-l border-white p-2'>Total</div>
        </div>

      </div>

      {quotations.map((quotation) => {
        let totalQuotation = 0
        return (
          <button className={`flex bg-white text-center border-t border-gray-900 hover:bg-gray-200 text-sm lg:text-base cursor-pointer font-bold ${disableQuotation.includes(quotation.id) ? 'text-gray-400 bg-gray-100' : ''}`}
            key={`${quotation.name}-${quotation.id}`}
            onClick={() => disable(quotation.id)}
          >
            <div className='p-2 w-40 lg:w-60 flex-none uppercase truncate'>{quotation.name}</div>

            <div className={`grid grid-cols-${gridCols} w-full`}>
              {quotation.types.map((type) => {
                totalQuotation = totalQuotation + type.day
                return (
                  <div className='p-2 border-l border-gray-200' key={`${type.type}-${type.id}`}>{type.day}</div>
                )
              })}

              <div className='border-l border-gray-200 p-2'>{totalQuotation}</div>
            </div>
          </button>
        )
      })}
    </div>
  )
}

export default Quotation
