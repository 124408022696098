import moment from 'moment'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  createProjectActionType,
  createProjectFailure,
  createProjectSuccess,
  createTypeActionType,
  createTypeFailure,
  createTypeSuccess,
  deleteReportActionType,
  deleteReportFailure,
  deleteReportSuccess,
  postTaskActionType,
  postTaskFailure,
  postTaskSuccess,
  TIMESHEET_TYPES,
  updateReportActionType,
  updateReportFailure,
  updateReportSuccess
} from '@containers/timesheet/actions/timesheet-actions'
import { apiCallWrapper } from '@services/api'
import { axiosDelete, axiosGet, axiosPost, axiosPut } from '@services/api/axios'
import { POST_PROJECTS_URL, REPORTS_URL, TYPES_URL } from '@services/api/timesheet'
import { RootState } from '@services/redux/config/reducers'
import { listProjects } from '@services/redux/projects/actions'
import { listReports } from '@services/redux/reports/actions'

function * postTaskWithId ({ payload: { projectId, reportTypeId, quotationId, date, duration, start, end } }: postTaskActionType) {
  try {
    const owner = yield select((state: RootState) => state.user.user?.id)
    const { success, error } = yield call(
      apiCallWrapper,
      axiosPost,
      REPORTS_URL,
      {
        project: projectId,
        reportType: reportTypeId,
        quotation: quotationId,
        date: date,
        duration: duration,
        owner: owner
      }
    )
    if (success) {
      yield put(postTaskSuccess())
      yield put(listReports(moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD')))
    } else {
      yield put(postTaskFailure(error))
    }
  } catch (error) {
    yield put(postTaskFailure(error))
  }
}

function * deleteReport ({ payload: ids, start, end }: deleteReportActionType) {
  try {
    for (let i = 0; i < ids.length; i += 1) {
      const stock: string = ids[i]
      const { success, error } = yield call(
        apiCallWrapper,
        axiosDelete,
        REPORTS_URL + `/${stock}`
      )

      if (success) {
        yield put(deleteReportSuccess())
        yield put(listReports(moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD')))
      } else {
        yield put(deleteReportFailure(error))
      }
    }
  } catch (error) {
    yield put(deleteReportFailure(error))
  }
}

function * updateReport ({ payload: { duration, id, projectId, reportTypeId, quotationId, start, end } }: updateReportActionType) {
  try {
    const { success, error } = yield call(
      apiCallWrapper,
      axiosPut,
      REPORTS_URL + `/${id}`,
      {
        duration: duration,
        project: projectId,
        reportType: reportTypeId,
        quotation: quotationId
      }
    )
    if (success) {
      yield put(updateReportSuccess())
      yield put(listReports(moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD')))
    } else {
      yield put(updateReportFailure(error))
    }
  } catch (error) {
    yield put(updateReportFailure(error))
  }
}

function * createProject ({ payload: { newProjectName, customerId } }: createProjectActionType) {
  try {
    const { success, error } = yield call(
      apiCallWrapper,
      axiosPost,
      POST_PROJECTS_URL,
      {
        name: newProjectName,
        customer: customerId
      }
    )
    if (success) {
      const { data } = yield call(
        apiCallWrapper,
        axiosGet,
        POST_PROJECTS_URL
      )
      yield put(createProjectSuccess(data))
      yield put(listProjects())
    } else {
      yield put(createProjectFailure(error))
    }
  } catch (error) {
    yield put(createProjectFailure(error))
  }
}

function * createType ({ payload: { newTypeName } }: createTypeActionType) {
  try {
    const { success, error } = yield call(
      apiCallWrapper,
      axiosPost,
      TYPES_URL,
      {
        type: newTypeName
      }
    )
    if (success) {
      const { data } = yield call(
        apiCallWrapper,
        axiosGet,
        TYPES_URL
      )
      yield put(createTypeSuccess(data))
    } else {
      yield put(createTypeFailure(error))
    }
  } catch (error) {
    yield put(createTypeFailure(error))
  }
}

export default function * timesheetSagas () {
  yield takeLatest(TIMESHEET_TYPES.POST_TASK, postTaskWithId)
  yield takeLatest(TIMESHEET_TYPES.DELETE, deleteReport)
  yield takeLatest(TIMESHEET_TYPES.UPDATE_REPORT, updateReport)
  yield takeLatest(TIMESHEET_TYPES.CREATE_PROJECT, createProject)
  yield takeLatest(TIMESHEET_TYPES.CREATE_TYPE, createType)
}
