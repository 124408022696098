import { Dialog, Transition } from '@headlessui/react'
import { BookmarkIcon, XIcon } from '@heroicons/react/outline'
import React, { Fragment, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'

import { createProjectAction } from '@containers/timesheet/actions/timesheet-actions'
import { OptionsType } from '@interfaces/options'
import { RootState } from '@services/redux/config/reducers'

interface Props {
  closeModal: () => void
}

export default function ModalCreateProject ({ closeModal }: Props) {
  const { data: customers } = useSelector((state: RootState) => state.customers)
  const [open, setOpen] = useState(true)
  const [newProjectName, setNewProjectName] = useState('')
  const [projectCustomer, setProjectCustomer] = useState('')

  const customersOptions = useMemo(() => {
    const data: OptionsType[] = []

    customers.forEach((customer) => {
      data.push({ value: customer.name, label: customer.name })
    })

    return data
  }, [customers?.length])

  const dispatch = useDispatch()

  const SubmitNewProject = (evt) => {
    evt.preventDefault()

    const customerId = customers.find(review => review.name === projectCustomer)
    if (customerId) {
      dispatch(createProjectAction({ newProjectName, customerId }))
      setOpen(false)
      closeModal()
    }
  }

  const setValuesToFalse = () => {
    closeModal()
    setOpen(false)
  }

  return (
    <Transition.Root as={Fragment} show={open}>
      <Dialog as='div' className='fixed z-10 inset-0 overflow-y-auto' onClose={setValuesToFalse}>
        <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}

          <span aria-hidden='true' className='hidden sm:inline-block sm:align-middle sm:h-screen'>
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6'>
              <div className='hidden sm:block absolute top-0 right-0 pt-4 pr-4'>
                <button
                  className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                  onClick={setValuesToFalse}
                  type='button'
                >
                  <span className='sr-only'>Close</span>

                  <XIcon aria-hidden='true' className='h-6 w-6' />

                </button>

              </div>

              <div className='sm:flex sm:items-start'>
                <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                  <BookmarkIcon aria-hidden='true' className='h-6 w-6 text-red-600' />
                </div>

                <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full'>
                  <Dialog.Title as='h3' className='text-lg leading-6 font-medium text-gray-900'>
                    Create Project
                  </Dialog.Title>

                  <div>

                    <form onSubmit={SubmitNewProject}>
                      <div className='grid grid-cols-2 gap-2 items-center mt-3'>
                        <Select onChange={(evt) => setProjectCustomer((evt as OptionsType).value)} options={customersOptions} />

                        <input
                          className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-black-500 sm:text-sm'
                          id='name'
                          name='name'
                          onChange={(evt) => setNewProjectName(evt.target.value)}
                          placeholder='Name'
                          type='text'
                        />
                      </div>
                    </form>
                  </div>

                </div>
              </div>

              <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                <button
                  className='w-full inline-flex bg-gray-200 justify-center rounded-md shadow-sm px-4 py-2 hover:bg-gray-400 text-gray-800 text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm'
                  onClick={SubmitNewProject}
                  type='button'
                >
                  Create
                </button>

                <button
                  className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-400 sm:mt-0 sm:w-auto sm:text-sm'
                  onClick={setValuesToFalse}
                  type='button'
                >
                  Cancel
                </button>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
