import { ReportType } from '@interfaces/report-type'
import { REPORT_TYPES_ACTION_TYPES } from '@services/redux/report-types/actions'

interface ReportTypesState {
  data: ReportType[]
  error?: Error
  isFetching: boolean
}

const initialState: ReportTypesState = {
  data: [],
  isFetching: false
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const reportTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORT_TYPES_ACTION_TYPES.LIST_REPORT_TYPES:
      return {
        ...state,
        error: undefined,
        isFetching: true
      }

    case REPORT_TYPES_ACTION_TYPES.LIST_REPORT_TYPES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.reportTypes
      }

    case REPORT_TYPES_ACTION_TYPES.LIST_REPORT_TYPES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }

    default:
      return state
  }
}

export default reportTypesReducer
