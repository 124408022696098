import moment, { Moment } from 'moment'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import UserSummaryDetails from '@containers/timesheet/components/user-summary-details'
import { DateTimeString } from '@interfaces/date'
import { Report } from '@interfaces/report'
import { RootState } from '@services/redux/config/reducers'
import { convertDuration } from '@services/tools/reports/formatter'

interface Props {
  dateArray: Moment[]
  start: DateTimeString
}

function TeamTimesheetBody ({ dateArray, start }: Props) {
  const { data: users } = useSelector((state: RootState) => state.users)
  const today = useMemo(() => moment(start).add(1, 'day'), [start])

  const currentWeekStart = today.startOf('isoWeek').format()
  const currentWeekEnd = today.endOf('isoWeek').format()
  const lastWeekStart = moment(currentWeekStart).subtract(1, 'week').format('YYYY-MM-DD')
  const lastWeekEnd = moment(currentWeekEnd).subtract(1, 'week').format('YYYY-MM-DD')

  const getUserLastWeekReports = (reports: Report[]) => {
    return reports
      ?.filter(r =>
        moment(r.date)
          .isBetween(lastWeekStart, lastWeekEnd, undefined, '[]'))
  }

  const getUserCurrentWeekReports = (reports: Report[]) => {
    return reports
      ?.filter(r =>
        moment(r.date)
          .isBetween(currentWeekStart, currentWeekEnd, undefined, '[]'))
  }

  const getDuration = (item, report) => {
    const workedProjects: number[] = report
      ?.filter(v => item.format('YYYY-MM-DD') === v.date)
      ?.map(i => i.duration)

    return workedProjects?.reduce((previous, current) => previous + current, 0)
  }

  const [toggledUsers, setToggledUsers] = useState<number[]>([])

  const toggleDetails = (id: number) => () => {
    setToggledUsers(toggledUsers.find(i => i === id)
      ? toggledUsers.filter(i => i !== id)
      : [...toggledUsers, id]
    )
  }

  return (
    <tbody>
      {users.map(user => {
        const lastWeekReports = getUserLastWeekReports(user.reports ?? [])
        const currentWeekReports = getUserCurrentWeekReports(user.reports ?? [])
        const isUserToggled = !!toggledUsers?.find(u => u === user.id)

        return (
          <>
            <tr>
              <td className={`text-center hover:bg-gray-300 border-solid border-r border-t font-mono whitespace-nowrap border-gray-200 font-bold relative capitalize sticky left-0 ${isUserToggled ? 'bg-gray-300' : 'bg-white'}`}>
                <button className='w-full h-full px-10 py-2 text-center font-bold' onClick={toggleDetails(user.id)}>
                  {user.username}
                </button>
              </td>

              {dateArray.map((item, index) => {
                const day = item.format('ddd')
                const today = moment().format('MM-DD')
                return (
                  <td
                    className={
                      `text-center border-solid border-t border-l mt-4 font-mono whitespace-nowrap px-2
                        ${isUserToggled ? ' bg-gray-300' : ' bg-white'}
                        ${day === 'Sun' || day === 'Sat' ? 'bg-gray-200 border-white' : ''}
                        ${today === item.format('MM-DD') ? 'bg-indigo-100 border-white' : ''}`
                    }
                  >
                    {(index < 7)
                      ? convertDuration(getDuration(item, lastWeekReports))
                      : convertDuration(getDuration(item, currentWeekReports))
                    }
                  </td>
                )
              })}
            </tr>

            {isUserToggled && (
              <UserSummaryDetails
                currentWeekReports={currentWeekReports}
                dates={dateArray}
                lastWeekReports={lastWeekReports}
              />
            )}
          </>
        )
      })}
    </tbody>
  )
}

export default TeamTimesheetBody
