export enum LOGIN_TYPES {
  LOGIN_USER = 'LOGIN_USER',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  LOGOUT = 'LOGOUT'
}

export const loginUserAction = (user) => {
  return {
    type: LOGIN_TYPES.LOGIN_USER,
    payload: user
  }
}

export type loginUserActionType = ReturnType<typeof loginUserAction>

export const loginSuccess = (user) => {
  return {
    type: LOGIN_TYPES.LOGIN_SUCCESS,
    user
  }
}

export type loginSuccessType = ReturnType<typeof loginSuccess>

export const loginFailure = (error) => {
  return {
    type: LOGIN_TYPES.LOGIN_FAILURE,
    payload: error
  }
}

export type loginFailureType = ReturnType<typeof loginFailure>

export const logout = () => {
  return {
    type: LOGIN_TYPES.LOGOUT
  }
}

export type logoutType = ReturnType<typeof logout>
