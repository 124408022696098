/* This example requires Tailwind CSS v2.0+ */
import { Disclosure, Menu } from '@headlessui/react'
import { MenuIcon, UserCircleIcon, XIcon } from '@heroicons/react/outline'
import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import logo from '@assets/logo-tilted-white.svg'
import { logout } from '@containers/login/actions/login-actions'
import { RootState } from '@services/redux/config/reducers'

const navigation = [
  { name: 'Timesheet', href: '/' },
  { name: 'Projects', href: '/projects' }
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const Navigation = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.user)

  const handleChange = () => {
    dispatch(logout())
    localStorage.clear()
  }

  const location = useLocation().pathname
  return (
    <Disclosure as='nav' className='bg-gray-700'>
      {({ open }) => (
        <>
          <div className='max-w-7xl mx-auto px-2 sm:px-6 lg:px-8'>
            <div className='relative flex items-center justify-between h-16'>
              <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                {/* Mobile menu button */}

                <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>

                  {open
                    ? (
                      <XIcon aria-hidden='true' className='block h-6 w-6' />
                    )
                    : (
                      <MenuIcon aria-hidden='true' className='block h-6 w-6' />
                    )}
                </Disclosure.Button>
              </div>

              <div className='flex-1 flex items-center sm:items-stretch sm:justify-start'>
                <div className='flex-shrink-0 flex items-center'>
                  <img
                    alt='Workflow'
                    className='block lg:hidden h-8 w-auto ml-12'
                    src={logo}
                  />

                  <img
                    alt='Workflow'
                    className='hidden lg:block h-8 w-auto'
                    src={logo}
                  />
                </div>

                <div className='hidden sm:block sm:ml-6'>
                  <div className='flex space-x-4'>
                    {navigation.map((item) => (
                      <Link
                        aria-current={item.href === location ? 'page' : undefined}
                        className={classNames(
                          item.href === location ? 'bg-white text-gray-700' : 'text-white hover:bg-white hover:text-gray-700',
                          'transform -skew-x-6 px-3 py-2 text-base font-medium'
                        )}
                        key={item.name}
                        to={item.href}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                {/* Profile dropdown */}

                <Menu as='div' className='ml-3 relative'>
                  <div>
                    <div className='flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'>
                      <div className='mx-5 text-center'>
                        <p className='text-base font-medium text-white'>{user.username}</p>

                        <button className='text-sm font-medium text-white transform -skew-x-6 px-1 py-0.5 hover:bg-white hover:text-gray-700' id='Logout' onClick={handleChange}>
                          Logout
                        </button>
                      </div>

                      <UserCircleIcon className='inline-block h-9 w-9 rounded-full text-white' />
                    </div>
                  </div>

                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='px-2 pt-2 pb-3 space-y-1'>
              {navigation.map((item) => (
                <Disclosure.Button
                  aria-current={item.href === location ? 'page' : undefined}
                  as='a'
                  className={classNames(
                    item.href === location ? 'bg-white text-red-800' : 'text-white hover:bg-white hover:text-red-800',
                    'block px-3 py-2 rounded-md text-base font-medium'
                  )}
                  href={item.href}
                  key={item.name}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default Navigation
