import { TIMESHEET_TYPES } from '@containers/timesheet/actions/timesheet-actions'
import { Report } from '@interfaces/report'
import { REPORTS_ACTION_TYPES } from '@services/redux/reports/actions'

interface ReportsState {
  data: Report[]
  error?: Error
  isFetching: boolean
}

const initialState: ReportsState = {
  data: [],
  isFetching: false
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const reportsReducer = (state = initialState, action): ReportsState => {
  switch (action.type) {
    case REPORTS_ACTION_TYPES.LIST_REPORTS:
      return {
        ...state,
        error: undefined,
        isFetching: true
      }

    case REPORTS_ACTION_TYPES.LIST_REPORTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.reports
      }

    case REPORTS_ACTION_TYPES.LIST_REPORTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }

    case TIMESHEET_TYPES.POST_TASK:
      return {
        ...state,
        data: [
          ...state.data,
          {
            id: Math.random() * 100000,
            date: action.payload.date,
            duration: action.payload.duration,
            project: {
              id: action.payload.projectId,
              name: action.payload.projectName
            },
            reportType: {
              id: action.payload.reportTypeId,
              type: action.payload.reportTypeName
            },
            quotation: {
              id: action.payload.quotationId,
              name: action.payload.quotationName
            }

          }
        ]
      }

    case TIMESHEET_TYPES.UPDATE_REPORT:
      return {
        ...state,
        data: [...state.data].map((report) => {
          if (report.id === action.payload.id) {
            return {
              ...report,
              duration: action.payload.duration
            }
          }

          return report
        })
      }

    case TIMESHEET_TYPES.DELETE:
      return {
        ...state,
        data: [...state.data].filter((report) => !action.payload.includes(report.id))
      }

    default:
      return state
  }
}

export default reportsReducer
