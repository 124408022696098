import React, { useState } from 'react'

import ModalCreateProject from '@containers/timesheet/components/modal'

const AddProject = () => {
  const [projectIsOpen, setProjectIsOpen] = useState(false)

  const closeModal = () => setProjectIsOpen(false)

  return (
    <div>
      <button className='rounded ml-2 mt-2 bg-gray-700 hover:bg-white hover:text-gray-700 border-2 border-transparent hover:border-gray-700 text-white font-bold py-2 px-6' id='addProject' onClick={!projectIsOpen ? () => setProjectIsOpen(true) : () => setProjectIsOpen(false)} type='button'>
        Add Project
      </button>

      {(projectIsOpen)
        ? <ModalCreateProject closeModal={closeModal} />
        : null }
    </div>
  )
}

export default AddProject
