import { call, put, select, takeLatest } from 'redux-saga/effects'

import { apiCallWrapper } from '@services/api'
import { axiosGet } from '@services/api/axios'
import { REPORTS_URL } from '@services/api/timesheet'
import { RootState } from '@services/redux/config/reducers'
import {
  listReportsFailure,
  listReportsSuccess,
  listReportsType,
  REPORTS_ACTION_TYPES
} from '@services/redux/reports/actions'

function * listReports ({ payload: { start, end } }: listReportsType) {
  try {
    const owner: string = yield select((state: RootState) => state.user.user?.id)
    const stockStart: string = start
    const stockEnd: string = end
    const { data, error, success } = yield call(
      apiCallWrapper,
      axiosGet,
      `${REPORTS_URL}?owner=${owner}${stockStart ? `&date_gt=${stockStart}` : ''}${stockEnd ? `&date_lt=${stockEnd}` : ''}`
    )

    if (success) {
      yield put(listReportsSuccess(data))
    } else {
      yield put(listReportsFailure(error))
    }
  } catch (error) {
    yield put(listReportsFailure(error))
  }
}

export default function * reportsSaga () {
  yield takeLatest(REPORTS_ACTION_TYPES.LIST_REPORTS, listReports)
}
