import { call, put, takeLatest } from 'redux-saga/effects'

import { apiCallWrapper } from '@services/api'
import { axiosGet } from '@services/api/axios'
import { CUSTOMERS_URL } from '@services/api/timesheet'
import {
  CUSTOMERS_ACTION_TYPES,
  listCustomersFailure,
  listCustomersSuccess
} from '@services/redux/customers/actions'

function * listCustomers () {
  try {
    const { data, error, success } = yield call(
      apiCallWrapper,
      axiosGet,
      CUSTOMERS_URL
    )

    if (success) {
      yield put(listCustomersSuccess(data))
    } else {
      yield put(listCustomersFailure(error))
    }
  } catch (error) {
    yield put(listCustomersFailure(error))
  }
}

export default function * customersSaga () {
  yield takeLatest(CUSTOMERS_ACTION_TYPES.LIST_CUSTOMERS, listCustomers)
}
