import { Customer } from '@interfaces/customer'

export enum CUSTOMERS_ACTION_TYPES {
  LIST_CUSTOMERS = 'LIST_CUSTOMERS',
  LIST_CUSTOMERS_SUCCESS = 'LIST_CUSTOMERS_SUCCESS',
  LIST_CUSTOMERS_FAILURE = 'LIST_CUSTOMERS_FAILURE'
}

export const listCustomers = () => ({
  type: CUSTOMERS_ACTION_TYPES.LIST_CUSTOMERS
})

export const listCustomersSuccess = (customers: Customer[]) => ({
  customers,
  type: CUSTOMERS_ACTION_TYPES.LIST_CUSTOMERS_SUCCESS
})

export const listCustomersFailure = (error: Error) => ({
  error,
  type: CUSTOMERS_ACTION_TYPES.LIST_CUSTOMERS_FAILURE
})

export type listCustomersType = ReturnType<typeof listCustomers>
export type listCustomersSuccessType = ReturnType<typeof listCustomersSuccess>
export type listCustomersFailureType = ReturnType<typeof listCustomersFailure>
