import moment from 'moment'
import React, { useMemo } from 'react'

import Headers from '@containers/timesheet/components/headers'
import TimesheetBody from '@containers/timesheet/components/timesheet-body'
import { DateTimeString } from '@interfaces/date'

interface Props {
  start: DateTimeString
  end: DateTimeString
  isOpen: boolean
  removeAddReportLine
}

function DisplayReports ({ start, end, isOpen, removeAddReportLine }: Props) {
  const mondayDate = useMemo(() => moment(start).add(1, 'day'), [start])
  const tuesdayDate = useMemo(() => moment(start).add(2, 'days'), [start])
  const wednesdayDate = useMemo(() => moment(start).add(3, 'days'), [start])
  const thursdayDate = useMemo(() => moment(start).add(4, 'days'), [start])
  const fridayDate = useMemo(() => moment(start).add(5, 'days'), [start])
  const saturdayDate = useMemo(() => moment(start).add(6, 'days'), [start])
  const sundayDate = useMemo(() => moment(start).add(7, 'days'), [start])

  const headers = useMemo(() => ([
    {
      header: 'Projects'
    },
    {
      header: 'Type'
    },
    {
      header: 'Quotation'
    },
    {
      header: mondayDate.format('MMM D'),
      day: 'Mon'
    },
    {
      header: tuesdayDate.format('MMM D'),
      day: 'Tue'
    },
    {
      header: wednesdayDate.format('MMM D'),
      day: 'Wed'
    },
    {
      header: thursdayDate.format('MMM D'),
      day: 'Thu'
    },
    {
      header: fridayDate.format('MMM D'),
      day: 'Fri'
    },
    {
      header: saturdayDate.format('MMM D'),
      day: 'Sat'
    },
    {
      header: sundayDate.format('MMM D'),
      day: 'Sun'
    },
    {
      header: 'Total'
    },
    {
      header: '#'
    }
  ]), [mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate])

  return (
    <div className='flex flex-col sm:rounded-lg overflow-hidden'>
      <table className='divide-y divide-gray-200 block w-full overflow-auto'>
        <Headers headers={headers} />

        <TimesheetBody end={end} isOpen={isOpen} removeAddReportLine={removeAddReportLine} start={start} />
      </table>
    </div>
  )
}

export default DisplayReports
