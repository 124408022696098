import { call, put, takeLatest } from 'redux-saga/effects'

import { apiCallWrapper } from '@services/api'
import { axiosGet } from '@services/api/axios'
import { USERS_URL } from '@services/api/timesheet'
import {
  getUserListFailure,
  getUserListSuccess,
  USER_LIST_ACTION_TYPES
} from '@services/redux/users/actions'

function * getUserList () {
  try {
    const { data, error, success } = yield call(
      apiCallWrapper,
      axiosGet,
      `${USERS_URL}?blocked=false&confirmed=true`
    )

    if (success) {
      yield put(getUserListSuccess(data))
    } else {
      yield put(getUserListFailure(error))
    }
  } catch (error) {
    yield put(getUserListFailure(error))
  }
}

export default function * userListSaga () {
  yield takeLatest(USER_LIST_ACTION_TYPES.GET_USER_LIST, getUserList)
}
