export const round = (number, dec) => {
  const durationDay = number / 420
  const factor = Math.pow(10, dec)
  const tempNumber = durationDay * factor
  const roundedTempNumber = Math.round(tempNumber)
  return roundedTempNumber / factor
}

export const roundDay = (number, dec) => {
  const factor = Math.pow(10, dec)
  const tempNumber = number * factor
  const roundedTempNumber = Math.round(tempNumber)
  return roundedTempNumber / factor
}
