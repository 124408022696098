import { Report } from '@interfaces/report'

export enum REPORTS_ACTION_TYPES {
  LIST_REPORTS = 'LIST_REPORTS',
  LIST_REPORTS_SUCCESS = 'LIST_REPORTS_SUCCESS',
  LIST_REPORTS_FAILURE = 'LIST_REPORTS_FAILURE'
}

export const listReports = (start: string, end: string) => ({
  type: REPORTS_ACTION_TYPES.LIST_REPORTS,
  payload: {
    start: start,
    end: end
  }
})

export const listReportsSuccess = (reports: Report[]) => ({
  reports,
  type: REPORTS_ACTION_TYPES.LIST_REPORTS_SUCCESS
})

export const listReportsFailure = (error: Error) => ({
  error,
  type: REPORTS_ACTION_TYPES.LIST_REPORTS_FAILURE
})

export type listReportsType = ReturnType<typeof listReports>
export type listReportsSuccessType = ReturnType<typeof listReportsSuccess>
export type listReportsFailureType = ReturnType<typeof listReportsFailure>
