import React, { ReactNode } from 'react'
import { Redirect, Route } from 'react-router-dom'

interface Props {
  children: ReactNode
  isLogged: boolean
  path: string
}

function PrivateRoute ({ children, isLogged, ...rest }: Props) {
  return (
    <Route
      {...rest}
      render={({ location }) => (
        isLogged
          ? (children)
          : (<Redirect to={{ pathname: '/login', state: { from: location } }} />)
      )}
    />
  )
}

export default PrivateRoute
