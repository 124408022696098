import { LOGIN_TYPES } from '@containers/login/actions/login-actions'
import { TIMESHEET_TYPES } from '@containers/timesheet/actions/timesheet-actions'
import { User } from '@interfaces/user'

interface LoginState {
  errors?: Error
  isFetching: boolean
  isLogged: boolean
  user?: User
}

const initialState: LoginState = {
  isFetching: false,
  isLogged: false
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_TYPES.LOGIN_USER:
      return {
        ...state,
        isFetching: true
      }

    case LOGIN_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        isLogged: true,
        isFetching: false
      }

    case LOGIN_TYPES.LOGIN_FAILURE:
      return {
        ...state,
        errors: typeof action.payload === 'string' ? JSON.parse(action.payload) : action.payload,
        isFetching: false
      }

    case LOGIN_TYPES.LOGOUT:
      return {
        ...state,
        user: null,
        isLogged: false,
        errors: {
          name: '',
          message: ''
        }
      }

    case TIMESHEET_TYPES.CREATE_TYPE_SUCCESS:
      return {
        ...state,
        types: action.payload
      }
    default:
      return state
  }
}

export default loginReducer
