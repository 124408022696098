import moment from 'moment'
import React, { useMemo, useState } from 'react'

import NextBackButtons from '@containers/timesheet/components/next-back-buttons'
import TeamHeaders from '@containers/timesheet/components/team-headers'
import TeamTimesheetBody from '@containers/timesheet/components/team-timesheet-body'
import { TeamHeader } from '@interfaces/header'

function TeamTimesheet () {
  const today = useMemo(moment, [])
  const [start, setStart] = useState(today.startOf('week').format())
  const mondayDate = useMemo(() => moment(start).add(1, 'day'), [start])
  const tuesdayDate = useMemo(() => moment(start).add(2, 'days'), [start])
  const wednesdayDate = useMemo(() => moment(start).add(3, 'days'), [start])
  const thursdayDate = useMemo(() => moment(start).add(4, 'days'), [start])
  const fridayDate = useMemo(() => moment(start).add(5, 'days'), [start])
  const saturdayDate = useMemo(() => moment(start).add(6, 'days'), [start])
  const sundayDate = useMemo(() => moment(start).add(7, 'days'), [start])
  const lastWeekMondayDate = useMemo(() => moment(start).add(1, 'day').subtract(1, 'week'), [start])
  const lastWeekTuesdayDate = useMemo(() => moment(start).add(2, 'days').subtract(1, 'week'), [start])
  const lastWeekWednesdayDate = useMemo(() => moment(start).add(3, 'days').subtract(1, 'week'), [start])
  const lastWeekThursdayDate = useMemo(() => moment(start).add(4, 'days').subtract(1, 'week'), [start])
  const lastWeekFridayDate = useMemo(() => moment(start).add(5, 'days').subtract(1, 'week'), [start])
  const lastWeekSaturdayDate = useMemo(() => moment(start).add(6, 'days').subtract(1, 'week'), [start])
  const lastWeekSundayDate = useMemo(() => moment(start).add(7, 'days').subtract(1, 'week'), [start])

  const dateArray = useMemo(() => ([
    lastWeekMondayDate,
    lastWeekTuesdayDate,
    lastWeekWednesdayDate,
    lastWeekThursdayDate,
    lastWeekFridayDate,
    lastWeekSaturdayDate,
    lastWeekSundayDate,
    mondayDate,
    tuesdayDate,
    wednesdayDate,
    thursdayDate,
    fridayDate,
    saturdayDate,
    sundayDate
  ]), [lastWeekMondayDate, lastWeekTuesdayDate, lastWeekWednesdayDate, lastWeekThursdayDate, lastWeekFridayDate, lastWeekSaturdayDate, lastWeekSundayDate, mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate])

  const headers: TeamHeader[] = [
    {
      header: 'Show: '
    }
  ]

  const onNextPressed = () => {
    setStart((moment(start).add(1, 'week').format()))
  }

  const onBackPressed = () => {
    setStart((moment(start).subtract(1, 'week').format()))
  }

  dateArray.forEach(item => {
    const date = item.format('MMM D')
    const day = item.format('ddd')
    headers.push({
      date: date,
      day: day
    })
  })

  return (
    <div className='flex flex-col sm:rounded-lg'>
      <div className='w-full text-2xl text-center font-bold text-gray-900 -mb-8'>
        {lastWeekMondayDate.format('DD/MM/YYYY')}

        <span className='inline-block mx-4'>to</span>

        {sundayDate.format('DD/MM/YYYY')}
      </div>

      <div className='-ml-4 md:-ml-8'>
        <NextBackButtons
          onBackPressed={onBackPressed}
          onNextPressed={onNextPressed}
        />
      </div>

      <table className='divide-y divide-gray-200 block w-full overflow-auto border border-gray-200 rounded-2xl mt-2'>
        <TeamHeaders headers={headers} />

        <TeamTimesheetBody dateArray={dateArray} start={start} />
      </table>
    </div>
  )
}

export default TeamTimesheet
