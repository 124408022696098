import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import Layout from '@components/layout/layout'
import NextBackButtons from '@containers/timesheet/components/next-back-buttons'
import TeamTimesheet from '@containers/timesheet/components/team-timesheet'
import DisplayReports from '@containers/timesheet/reports/display-reports'
import { Form } from '@containers/timesheet/task/insert-task'
import { listCustomers } from '@root/services/redux/customers/actions'
import { listProjects } from '@root/services/redux/projects/actions'
import { listQuotations } from '@root/services/redux/quotations/actions'
import { listReportTypes } from '@root/services/redux/report-types/actions'
import { listLastWeekReports } from '@services/redux/last-week-reports/actions'
import { listReports } from '@services/redux/reports/actions'
import { getUserList } from '@services/redux/users/actions'

const Timesheet = () => {
  const today = useMemo(moment, [])
  const [{
    end,
    start
  }, setDates] = useState({
    end: today.clone().endOf('week').add(2, 'days').format(),
    start: today.startOf('week').format()
  })
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()

  const onNextPressed = () => {
    setDates(({ end, start }) => ({
      end: moment(end).add(1, 'week').format(),
      start: moment(start).add(1, 'week').format()
    }))
  }

  const onBackPressed = () => {
    setDates(({ end, start }) => ({
      end: moment(end).subtract(1, 'week').format(),
      start: moment(start).subtract(1, 'week').format()
    }))
  }

  const onAddReportPressed = () => setIsOpen(open => !open)

  const removeAddReportLine = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    dispatch(listQuotations())
    dispatch(listCustomers())
    dispatch(listReportTypes())
    dispatch(listProjects())
    dispatch(getUserList())
  }, [])

  useEffect(() => {
    dispatch(listReports(moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD')))
    dispatch(listLastWeekReports(moment(start).subtract(1, 'week').format('YYYY-MM-DD'), moment(end).subtract(1, 'week').format('YYYY-MM-DD')))
  }, [start, end])

  return (
    <Layout>
      <div>
        <div className='py-6'>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mb-4'>
            <h1 className='text-2xl text-center uppercase font-bold text-gray-900 underline'>Timesheet</h1>
          </div>

        </div>

        <div className=''>

          <div className='max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
            <div className='-ml-4 md:-ml-8'>
              <NextBackButtons
                onBackPressed={onBackPressed}
                onNextPressed={onNextPressed}
              />
            </div>

            {/* Replace with your content */}

            <div className='py-4'>

              <div className='border -mt-2 border-gray-200 rounded-2xl overflow-hidden'>
                <div>
                  <DisplayReports
                    end={end}
                    isOpen={isOpen}
                    removeAddReportLine={removeAddReportLine}
                    start={start}
                  />
                </div>
              </div>

            </div>

            <Form
              isOpen={isOpen}
              onAddReportPressed={onAddReportPressed}
            />

          </div>

        </div>

        <div className='mt-8 max-w-7xl mx-auto px-4 sm:px-6 md:px-8'>
          <div className='py-6 border-t-2 border-gray-700'>
            <h1 className='text-2xl text-center uppercase font-bold text-gray-900 underline'>Team summary</h1>
          </div>

          <div className='-mt-2 overflow-hidden mb-8'>
            <TeamTimesheet />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Timesheet
