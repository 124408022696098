import { Quotation } from '@interfaces/quotation'

export enum QUOTATIONS_ACTION_TYPES {
  LIST_QUOTATIONS = 'LIST_QUOTATIONS',
  LIST_QUOTATIONS_SUCCESS = 'LIST_QUOTATIONS_SUCCESS',
  LIST_QUOTATIONS_FAILURE = 'LIST_QUOTATIONS_FAILURE'
}

export const listQuotations = () => ({
  type: QUOTATIONS_ACTION_TYPES.LIST_QUOTATIONS
})

export const listQuotationsSuccess = (quotations: Quotation[]) => ({
  quotations,
  type: QUOTATIONS_ACTION_TYPES.LIST_QUOTATIONS_SUCCESS
})

export const listQuotationsFailure = (error: Error) => ({
  error,
  type: QUOTATIONS_ACTION_TYPES.LIST_QUOTATIONS_FAILURE
})

export type listQuotationsType = ReturnType<typeof listQuotations>
export type listQuotationsSuccessType = ReturnType<typeof listQuotationsSuccess>
export type listQuotationsFailureType = ReturnType<typeof listQuotationsFailure>
