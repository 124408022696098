import { Project } from '@interfaces/project'

export enum PROJECTS_ACTION_TYPES {
  LIST_PROJECTS = 'LIST_PROJECTS',
  LIST_PROJECTS_SUCCESS = 'LIST_PROJECTS_SUCCESS',
  LIST_PROJECTS_FAILURE = 'LIST_PROJECTS_FAILURE'
}

export const listProjects = () => ({
  type: PROJECTS_ACTION_TYPES.LIST_PROJECTS
})

export const listProjectsSuccess = (projects: Project[]) => ({
  projects,
  type: PROJECTS_ACTION_TYPES.LIST_PROJECTS_SUCCESS
})

export const listProjectsFailure = (error: Error) => ({
  error,
  type: PROJECTS_ACTION_TYPES.LIST_PROJECTS_FAILURE
})

export type listProjectsType = ReturnType<typeof listProjects>
export type listProjectsSuccessType = ReturnType<typeof listProjectsSuccess>
export type listProjectsFailureType = ReturnType<typeof listProjectsFailure>
