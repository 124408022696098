import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import logo from '@assets/logo-tilted.svg'
import { loginUserAction } from '@containers/login/actions/login-actions'
import { RootState } from '@services/redux/config/reducers'

const Login = () => {
  const { isLogged, errors, isFetching } = useSelector((state: RootState) => state.user)
  const history = useHistory()

  useEffect(() => {
    if (isLogged) {
      history.replace('/')
    }
  }, [isLogged])

  const dispatch = useDispatch()

  const { errors: { email: emailError }, setFieldError, handleSubmit, handleChange, values: { email, password } } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: values => {
      dispatch(loginUserAction(values))
    }
  })

  useEffect(() => {
    if (errors) {
      setFieldError('email', errors?.message?.[0]?.messages?.[0]?.message)
    }
  }, [errors])

  return (
    <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <img alt='Tilted' className='mx-auto h-12 w-auto' src={logo} />
      </div>

      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
          <form action='#' className='space-y-6' method='POST' onSubmit={handleSubmit}>
            <div>
              <label className='block text-sm font-medium text-gray-700' htmlFor='email'> Email </label>

              <div className='mt-1'>
                <input
                  autoComplete='email'
                  className={`${(emailError) ? 'border-2 border-red-500 ' : ''}appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  id='email'
                  name='email'
                  onChange={handleChange}
                  required
                  type='email'
                  value={email}
                />
              </div>
            </div>

            <div>
              <label className='block text-sm font-medium text-gray-700' htmlFor='password'> Password </label>

              <div className='mt-1'>
                <input
                  autoComplete='current-password'
                  className={`${(emailError) ? 'border-2 border-red-500 ' : ''}appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  id='password'
                  name='password'
                  onChange={handleChange}
                  required
                  type='password'
                  value={password}
                />
              </div>
            </div>

            <div>{(emailError) ? <h1 style={{ color: 'red' }}>{emailError}</h1> : null}</div>

            <div className='flex items-center justify-between'>
              <div className='flex items-center'>
                <input
                  className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
                  id='remember-me'
                  name='remember-me'
                  type='checkbox'
                />

                <label
                  className='ml-2 block text-sm text-gray-900'
                  htmlFor='remember-me'
                >
                  Remember me
                </label>
              </div>
            </div>

            <div>
              {(isFetching)
                ? <button className='w-full flex justify-center py-2 px-4 border border-current rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-red-900 to-red-500 hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' type='submit'>
                  Loading
                </button>
                : <button className='w-full flex justify-center py-2 px-4 border border-current rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-red-900 to-red-500 hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' type='submit'>
                  Se connecter
                </button>
              }
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
