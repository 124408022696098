import { Report } from '@interfaces/report'
import { REPORTS_LAST_WEEK_ACTION_TYPES } from '@services/redux/last-week-reports/actions'

interface ReportsState {
  data: Report[]
  error?: Error
  isFetching: boolean
}

const initialState: ReportsState = {
  data: [],
  isFetching: false
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const LastWeekReportsReducer = (state = initialState, action): ReportsState => {
  switch (action.type) {
    case REPORTS_LAST_WEEK_ACTION_TYPES.LIST_LAST_WEEK_REPORTS:
      return {
        ...state,
        error: undefined,
        isFetching: true
      }

    case REPORTS_LAST_WEEK_ACTION_TYPES.LIST_LAST_WEEK_REPORTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.reports
      }

    case REPORTS_LAST_WEEK_ACTION_TYPES.LIST_LAST_WEEK_REPORTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }

    default:
      return state
  }
}

export default LastWeekReportsReducer
