import React, { MouseEventHandler } from 'react'

import AddProject from '@containers/timesheet/components/add-projects'

interface Props {
  onAddReportPressed: MouseEventHandler<HTMLButtonElement>
  isOpen: boolean
}

export const Form = ({ onAddReportPressed, isOpen }: Props) => {
  return (
    <div>
      <div className='float-left'>
        <button className='rounded ml-2 mt-2 bg-gray-700 hover:bg-white hover:text-gray-700 border-2 border-transparent hover:border-gray-700 text-white font-bold py-2 px-6'
          id='addReport'
          onClick={onAddReportPressed}
          type='button'
        >
          Add Report
        </button>
      </div>

      <div>
        <AddProject />
      </div>
    </div>
  )
}
