import { ReportType } from '@interfaces/report-type'

export enum REPORT_TYPES_ACTION_TYPES {
  LIST_REPORT_TYPES = 'LIST_REPORT_TYPES',
  LIST_REPORT_TYPES_SUCCESS = 'LIST_REPORT_TYPES_SUCCESS',
  LIST_REPORT_TYPES_FAILURE = 'LIST_REPORT_TYPES_FAILURE'
}

export const listReportTypes = () => ({
  type: REPORT_TYPES_ACTION_TYPES.LIST_REPORT_TYPES
})

export const listReportTypesSuccess = (reportTypes: ReportType[]) => ({
  reportTypes,
  type: REPORT_TYPES_ACTION_TYPES.LIST_REPORT_TYPES_SUCCESS
})

export const listReportTypesFailure = (error: Error) => ({
  error,
  type: REPORT_TYPES_ACTION_TYPES.LIST_REPORT_TYPES_FAILURE
})

export type listReportTypesType = ReturnType<typeof listReportTypes>
export type listReportTypesSuccessType = ReturnType<typeof listReportTypesSuccess>
export type listReportTypesFailureType = ReturnType<typeof listReportTypesFailure>
