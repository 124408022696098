import { call, put, takeLatest } from 'redux-saga/effects'

import { apiCallWrapper } from '@services/api'
import { axiosGet } from '@services/api/axios'
import { QUOTATIONS_URL } from '@services/api/timesheet'
import {
  listQuotationsFailure,
  listQuotationsSuccess,
  QUOTATIONS_ACTION_TYPES
} from '@services/redux/quotations/actions'

function * listQuotations () {
  try {
    const { data, error, success } = yield call(
      apiCallWrapper,
      axiosGet,
      QUOTATIONS_URL
    )

    if (success) {
      yield put(listQuotationsSuccess(data))
    } else {
      yield put(listQuotationsFailure(error))
    }
  } catch (error) {
    yield put(listQuotationsFailure(error))
  }
}

export default function * quotationsSaga () {
  yield takeLatest(QUOTATIONS_ACTION_TYPES.LIST_QUOTATIONS, listQuotations)
}
