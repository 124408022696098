import { call, put, select, takeLatest } from 'redux-saga/effects'

import { apiCallWrapper } from '@services/api'
import { axiosGet } from '@services/api/axios'
import { REPORTS_URL } from '@services/api/timesheet'
import { RootState } from '@services/redux/config/reducers'
import {
  listLastWeekReportsFailure,
  listLastWeekReportsSuccess,
  listLastWeekReportsType,
  REPORTS_LAST_WEEK_ACTION_TYPES
} from '@services/redux/last-week-reports/actions'

function * listLastWeekReports ({ payload: { start, end } }: listLastWeekReportsType) {
  try {
    const owner: string = yield select((state: RootState) => state.user.user?.id)
    const stockStart: string = start
    const stockEnd: string = end
    const { data, error, success } = yield call(
      apiCallWrapper,
      axiosGet,
      `${REPORTS_URL}?owner=${owner}${stockStart ? `&date_gt=${stockStart}` : ''}${stockEnd ? `&date_lt=${stockEnd}` : ''}`
    )

    if (success) {
      yield put(listLastWeekReportsSuccess(data))
    } else {
      yield put(listLastWeekReportsFailure(error))
    }
  } catch (error) {
    yield put(listLastWeekReportsFailure(error))
  }
}

export default function * lastWeekReportsSaga () {
  yield takeLatest(REPORTS_LAST_WEEK_ACTION_TYPES.LIST_LAST_WEEK_REPORTS, listLastWeekReports)
}
