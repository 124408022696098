export enum TIMESHEET_TYPES {
  POST_TASK = 'POST_TASK',
  POST_TASK_SUCCESS = 'POST_TASK_SUCCESS',
  POST_TASK_FAILURE = 'POST_TASK_FAILURE',
  DELETE = 'DELETE_REPORTS',
  DELETE_SUCCESS = 'DELETE_REPORTS_SUCCESS',
  DELETE_FAILURE = 'DELETE_REPORTS_FAILURE',
  UPDATE_REPORT = 'UPDATE_REPORT',
  UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS',
  UPDATE_REPORT_FAILURE = 'UPDATE_REPORT_FAILURE',
  CREATE_PROJECT = 'CREATE_PROJECT',
  CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE',
  CREATE_TYPE = 'CREATE_TYPE',
  CREATE_TYPE_SUCCESS = 'CREATE_TYPE_SUCCESS',
  CREATE_TYPE_FAILURE = 'CREATE_TYPE_FAILURE'
}

export const postTaskAction = (taskData) => {
  return {
    type: TIMESHEET_TYPES.POST_TASK,
    payload: taskData
  }
}

export type postTaskActionType = ReturnType<typeof postTaskAction>

export const postTaskSuccess = () => {
  return {
    type: TIMESHEET_TYPES.POST_TASK_SUCCESS
  }
}

export type postTaskSuccessType = ReturnType<typeof postTaskSuccess>

export const postTaskFailure = (error) => {
  return {
    type: TIMESHEET_TYPES.POST_TASK_FAILURE,
    payload: error
  }
}

export type postTaskSFailureType = ReturnType<typeof postTaskFailure>

export const deleteReportAction = (ids, start, end) => {
  return {
    type: TIMESHEET_TYPES.DELETE,
    payload: ids,
    start,
    end
  }
}

export type deleteReportActionType = ReturnType<typeof deleteReportAction>

export const deleteReportSuccess = () => {
  return {
    type: TIMESHEET_TYPES.DELETE_SUCCESS
  }
}

export type deleteReportSuccessType = ReturnType<typeof deleteReportSuccess>

export const deleteReportFailure = (error) => {
  return {
    type: TIMESHEET_TYPES.DELETE_FAILURE,
    payload: error
  }
}

export type deleteReportFailureType = ReturnType<typeof deleteReportFailure>

export const updateReportAction = (data: {duration: number; id: number; projectId; reportTypeId; quotationId; start; end}) => {
  return {
    type: TIMESHEET_TYPES.UPDATE_REPORT,
    payload: data
  }
}

export type updateReportActionType = ReturnType<typeof updateReportAction>

export const updateReportSuccess = () => {
  return {
    type: TIMESHEET_TYPES.UPDATE_REPORT_SUCCESS
  }
}

export type updateReportSuccessType = ReturnType<typeof updateReportSuccess>

export const updateReportFailure = (error) => {
  return {
    type: TIMESHEET_TYPES.UPDATE_REPORT_FAILURE,
    payload: error
  }
}

export type updateReportFailureType = ReturnType<typeof updateReportFailure>

export const createProjectAction = (data) => {
  return {
    type: TIMESHEET_TYPES.CREATE_PROJECT,
    payload: data
  }
}

export type createProjectActionType = ReturnType<typeof createProjectAction>

export const createProjectSuccess = (data) => {
  return {
    type: TIMESHEET_TYPES.CREATE_PROJECT_SUCCESS,
    payload: data
  }
}

export type createProjectSuccessType = ReturnType<typeof createProjectSuccess>

export const createProjectFailure = (error) => {
  return {
    type: TIMESHEET_TYPES.CREATE_PROJECT_FAILURE,
    payload: error
  }
}

export type createProjectFailureType = ReturnType<typeof createProjectFailure>

export const createTypeAction = (data) => {
  return {
    type: TIMESHEET_TYPES.CREATE_TYPE,
    payload: data
  }
}

export type createTypeActionType = ReturnType<typeof createTypeAction>

export const createTypeSuccess = (data) => {
  return {
    type: TIMESHEET_TYPES.CREATE_TYPE_SUCCESS,
    payload: data
  }
}

export type createTypeSuccessType = ReturnType<typeof createTypeSuccess>

export const createTypeFailure = (error) => {
  return {
    type: TIMESHEET_TYPES.CREATE_TYPE_FAILURE,
    payload: error
  }
}

export type createTypeFailureType = ReturnType<typeof createTypeFailure>
