import { applyMiddleware, createStore } from 'redux'
import logger from 'redux-logger'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'

import { rootReducer } from '@services/redux/config/reducers'
import sagas from '@services/redux/config/sagas'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['user']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}

const store = createStore(persistedReducer, applyMiddleware(...middlewares))

const persistor = persistStore(store)

sagaMiddleware.run(sagas)

export default () => ({
  persistor,
  store
})
