import { TrashIcon } from '@heroicons/react/outline'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'

import { deleteReportAction, postTaskAction, updateReportAction } from '@containers/timesheet/actions/timesheet-actions'
import AddReport from '@containers/timesheet/components/add-reports'
import ModalDelete from '@containers/timesheet/components/modals-delete'
import { DateTimeString } from '@interfaces/date'
import { OptionsType } from '@interfaces/options'
import { Report } from '@interfaces/report'
import { RootState } from '@services/redux/config/reducers'
import { formatReportLines } from '@services/tools/reports/formatter'

interface Props {
  start: DateTimeString
  end: DateTimeString
  isOpen: boolean
  removeAddReportLine: () => void
}

const TimesheetBody = ({ start, end, isOpen, removeAddReportLine }: Props) => {
  const { data: reports } = useSelector((state: RootState) => state.reports)
  const { data: projects } = useSelector((state: RootState) => state.projects)
  const { data: types } = useSelector((state: RootState) => state.reportTypes)
  const { data: quotations } = useSelector((state: RootState) => state.quotations)
  const [duration, setDuration] = useState('')
  const dispatch = useDispatch()
  const [state, setState] = useState(true)
  const [stockId, setStockId] = useState(0)
  const [deleteState, setDeleteState] = useState(false)
  const [stock, setStock] = useState<Report[]>()

  const reportLines = useMemo(() => formatReportLines(reports), [reports])

  const mondayDate = useMemo(() => moment(start).add(1, 'day'), [start])
  const tuesdayDate = useMemo(() => moment(start).add(2, 'days'), [start])
  const wednesdayDate = useMemo(() => moment(start).add(3, 'days'), [start])
  const thursdayDate = useMemo(() => moment(start).add(4, 'days'), [start])
  const fridayDate = useMemo(() => moment(start).add(5, 'days'), [start])
  const saturdayDate = useMemo(() => moment(start).add(6, 'days'), [start])
  const sundayDate = useMemo(() => moment(start).add(7, 'days'), [start])

  const closeModal = () => setDeleteState(false)

  const dateArray = useMemo(() => ([
    mondayDate,
    tuesdayDate,
    wednesdayDate,
    thursdayDate,
    fridayDate,
    saturdayDate,
    sundayDate
  ]), [mondayDate, tuesdayDate, wednesdayDate, thursdayDate, fridayDate, saturdayDate, sundayDate])

  const currencyFormatter = (data: string) => {
    if (!Number(data)) {
      return '__:__'
    }
    if (data.length === 1) {
      return `__:_${data}`
    } else if (data.length === 2) {
      return `__:${data}`
    } else if (data.length === 3) {
      return `_${data[0]}:${data[1]}${data[2]}`
    } else if (data.length === 4) {
      return `${data[0]}${data[1]}:${data[2]}${data[3]}`
    }
    return ''
  }

  const toMinutes = (mins) => {
    const hoursDotMinutes = mins
    const fieldArray = hoursDotMinutes.split(':')
    const firstPart = fieldArray[1].split('_').join('0')
    const secondPart = fieldArray[0].split('_').join('0')
    const minutes = parseInt(secondPart) * 60 + parseInt(firstPart)
    return minutes
  }

  const handleSubmit = (e, reportTypeId, projectId, quotation, projectName, reportTypeName, date, id) => {
    e.preventDefault()
    e.target.value = ''
    const durationInt = toMinutes((duration === '') ? '00:10' : duration)
    const quotationId = quotation ? quotation.id : null

    if (durationInt === 0) {
      dispatch(deleteReportAction([id], start, end))
    }

    if (durationInt && !isNaN(durationInt)) {
      if (id) {
        dispatch(updateReportAction({ duration: durationInt, id, projectId, reportTypeId, quotationId, start, end }))
      } else {
        dispatch(postTaskAction({
          reportTypeId,
          projectId,
          quotationId,
          date: date.format('YYYY-MM-DD'),
          duration: durationInt,
          projectName,
          reportTypeName,
          start,
          end
        }))
      }
    }
    setDuration('')
    setState(true)
  }

  const handleClick = (reports) => {
    setStock(reports)
    setDeleteState(true)
  }

  const classNameManagement = (value, index) => {
    const today = moment().format('MM-DD')

    if (value.format('MM-DD') === today) {
      return 'bg-indigo-100 border-white'
    } else if (index < 5 && index < 9) {
      return ''
    } else {
      return 'bg-gray-200 border-white'
    }
  }

  const convertDuration = (num) => {
    const hours = (num / 60)
    const rhours = Math.floor(hours)
    const minutes = (hours - rhours) * 60
    const rminutes = Math.round(minutes)
    return (`${((rhours < 10) ? `0${rhours}` : rhours)}:${((rminutes < 10) ? `0${rminutes}` : rminutes)}`)
  }

  const inputManagement = (report, item, value) => {
    return (
      <NumberFormat
        allowEmptyFormatting
        allowLeadingZeros={true}
        allowNegative={false}
        className='bg-transparent h-10 text-center text-gray-500 w-full placeholder-gray-500 focus:outline-none placeholder-opacity-80 font-mono'
        decimalSeparator=''
        format={(report && state) ? convertDuration(report.duration) : (report.id === stockId) ? currencyFormatter : convertDuration(report.duration)}
        isNumericString={false}
        name='account-number'
        onBlur={((duration !== '') ? (e) => handleSubmit(e, item.reportType.id, item.project.id, item.quotation, item.project.name, item.reportType.type, value, report?.id) : () => setState(true))}
        onChange={(e) => setDuration(e.target.value)}
        onClick={() => {
          setState(false)
          setStockId(report.id)
        }}
        type='text'
      />
    )
  }
  const numberFormatManagement = (item, value, report) => {
    return (
      <NumberFormat
        allowEmptyFormatting
        allowLeadingZeros={true}
        allowNegative={false}
        className='bg-transparent h-10 text-center w-full placeholder-gray-500 focus:outline-none placeholder-opacity-80 font-mono'
        decimalSeparator=''
        format={currencyFormatter}
        isNumericString={false}
        mask='_'
        onBlur={((duration !== '' && duration !== '__:__') ? (e) => handleSubmit(e, item.reportType.id, item.project.id, item.quotation, item.project.name, item.reportType.type, value, report?.id) : () => null)}
        onChange={(e) => setDuration(e.target.value)}
        prefix=''
        thousandSeparator={false}
        thousandsGroupStyle='thousand'
        value={(report) ? Number(duration) / 100 : null}
      />
    )
  }

  const getTotalDays = (value) => {
    let stock = 0
    reports.forEach((item) => {
      if (value.format('YYYY-MM-DD') === item.date) {
        stock += item.duration
      }
    })
    return stock
  }

  let totalWeek = 0

  const optionsProjects: OptionsType[] = []
  projects.forEach((item) => {
    optionsProjects.push({ value: item.name, label: item.name })
  })

  const optionsTypes: OptionsType[] = []
  types.forEach((item) => {
    optionsTypes.push({ value: item.type, label: item.type })
  })

  const optionsQuotations = (item) => {
    const project = projects.find(project => project.id === item.project.id)
    const data: OptionsType[] = [{ value: 'none', label: 'none' }]
    project?.quotations?.forEach((quotation) => {
      data.push({ value: quotation.name, label: quotation.name })
    })
    return data
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: 'none',
      border: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      height: 54
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: 0
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: 'bold',
      cursor: 'pointer',
      backgroundColor: state.isFocused && 'rgba(55, 65, 81, 1)',
      color: state.isFocused && 'white'
    }),
    menuList: (base) => ({
      ...base,

      '::-webkit-scrollbar': {
        width: '5px',
        height: '0px'
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888'
      }
    })
  }

  const handleChangeProject = (newValue, reports) => {
    const projectId = projects.find(review => review.name === newValue)

    reports.reports.forEach((report: Report) => {
      dispatch(updateReportAction({ duration: report.duration, id: report.id, projectId, reportTypeId: report.reportType, quotationId: null, start, end }))
    })
  }

  const handleChangeType = (newValue, reports) => {
    const reportTypeId = types.find(review => review.type === newValue)

    reports.reports.forEach((report: Report) => {
      dispatch(updateReportAction({ duration: report.duration, id: report.id, projectId: report.project, reportTypeId, quotationId: report.quotation, start, end }))
    })
  }

  const handleChangeQuotation = (newValue, reports) => {
    let quotationId = quotations.find(review => review.name === newValue)
    quotationId = typeof quotationId === 'undefined' ? null : quotationId

    reports.reports.forEach((report: Report) => {
      dispatch(updateReportAction({ duration: report.duration, id: report.id, projectId: report.project, reportTypeId: report.reportType, quotationId, start, end }))
    })
  }

  return (
    <tbody>
      {reportLines.map((item, index) => (
        <tr key={`${item.project.name}-${item?.reportType?.type ?? ''}-${index}`}>
          <td className='text-center w-60 truncate border-solid border-b border-r mt-4 font-mono whitespace-nowrap hover:bg-gray-300 font-bold' >
            <Select components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              defaultValue={{ label: item.project.name, value: item.project.name }}
              menuPortalTarget={document.querySelector('body')}
              onChange={(evt) => handleChangeProject((evt as OptionsType).value, item)}
              options={optionsProjects}
              styles={customStyles}
            />
          </td>

          <td className='text-center truncate border-solid border-b border-r mt-4 font-mono whitespace-nowrap hover:bg-gray-300 font-bold' >
            <Select components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              defaultValue={{ label: item.reportType.type, value: item.reportType.type }}
              menuPortalTarget={document.querySelector('body')}
              onChange={(evt) => handleChangeType((evt as OptionsType).value, item)}
              options={optionsTypes}
              styles={customStyles}
            />
          </td>

          <td className='text-center truncate border-solid border-b border-r mt-4 font-mono whitespace-nowrap hover:bg-gray-300 font-bold' >
            <Select components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              defaultValue={item.quotation ? { label: item.quotation.name, value: item.quotation.name } : { label: 'none', value: 'none' }}
              menuPortalTarget={document.querySelector('body')}
              onChange={(evt) => handleChangeQuotation((evt as OptionsType).value, item)}
              options={optionsQuotations(item)}
              styles={customStyles}
            />
          </td>

          {dateArray.map((value, index) => {
            const report = item.reports.find(report => moment(report.date).diff(value, 'days') === 0)

            return (
              <td className={`border-solid border-t border-l mt-4 hover:bg-gray-300 whitespace-nowrap ${classNameManagement(value, index)}`} key={`${value.format('YYYY-MM-DD')}-${item.project.name}-${item?.reportType?.type ?? ''}`}>
                {report ? inputManagement(report, item, value) : numberFormatManagement(item, value, report)}
              </td>
            )
          })}

          <td className='px-4 text-center border-solid border-b font-mono border-r whitespace-nowrap' >
            {convertDuration(
              item.reports.map(r => r.duration)
                .reduce((accumulator, a) => accumulator + a, 0)
            )}
          </td>

          <td className=' group text-center border-solid border-b hover:bg-gray-100 font-mono whitespace-nowrap tilted-red sticky right-0' >
            <button className='w-full h-full py-3 px-1' id='button' onClick={() => handleClick(item.reports)}>
              <TrashIcon className='inline-block h-7 w-7 rounded-full text-white group-hover:text-gray-700' />
            </button>
          </td>
        </tr>
      ))}

      {(isOpen) ? <AddReport dateArray={dateArray} end={end} removeAddReportLine={removeAddReportLine} start={start} /> : null}

      {(deleteState) ? <ModalDelete closeModal={closeModal} end={end} reports={stock} start={start} /> : null}

      <tr className='border-t-2 border-gray-300'>
        <td className='text-center w-4 border-solid border-r mt-4 font-mono text-lg font-bold'>Total</td>

        <td className='text-center border-solid border-r mt-4 font-mono text-lg'>-</td>

        <td className='text-center border-solid border-r mt-4 font-mono text-lg'>-</td>

        {dateArray.map((value, index) => {
          totalWeek = totalWeek + getTotalDays(value)
          return (
            <td className={`text-center px-4 border-solid border-l font-mono mt-4 hover:bg-gray-300 h-12 text-lg ${classNameManagement(value, index)}`} key={`total-${index}`}>
              {convertDuration(getTotalDays(value))}
            </td>
          )
        })}

        <td className='text-center font-bold border-solid border-r text-gray-900 text-lg'>{convertDuration(totalWeek)}</td>

        <td className='text-center font-mono border-solid text-white text-lg sticky right-0 tilted-red'>-</td>
      </tr>
    </tbody>
  )
}

export default TimesheetBody
