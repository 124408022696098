import { fork } from '@redux-saga/core/effects'

import authSagas from '@containers/login/saga/login-sagas'
import timesheetSagas from '@containers/timesheet/saga/timesheet-saga'
import customersSaga from '@services/redux/customers/saga'
import lastWeekReportsSaga from '@services/redux/last-week-reports/saga'
import projectsSaga from '@services/redux/projects/saga'
import quotationsSaga from '@services/redux/quotations/saga'
import reportTypesSaga from '@services/redux/report-types/saga'
import reportsSaga from '@services/redux/reports/saga'
import userListSaga from '@services/redux/users/saga'

export default function * sagas () {
  yield fork(authSagas)
  yield fork(projectsSaga)
  yield fork(reportsSaga)
  yield fork(lastWeekReportsSaga)
  yield fork(reportTypesSaga)
  yield fork(timesheetSagas)
  yield fork(userListSaga)
  yield fork(customersSaga)
  yield fork(quotationsSaga)
}
