import { User } from '@interfaces/user'
import {
  getUserListFailureType,
  getUserListSuccessType,
  USER_LIST_ACTION_TYPES,
  UserListActionTypes
} from '@services/redux/users/actions'

interface UserListState {
  data: User[]
  error?: Error
  isFetching: boolean
}

const initialState: UserListState = {
  data: [],
  isFetching: false
}

const userListReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: UserListActionTypes
): UserListState => {
  switch (action.type) {
    case USER_LIST_ACTION_TYPES.GET_USER_LIST:
      return {
        ...state,
        error: undefined,
        isFetching: true
      }

    case USER_LIST_ACTION_TYPES.GET_USER_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: (action as getUserListFailureType).error
      }

    case USER_LIST_ACTION_TYPES.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: (action as getUserListSuccessType).users
      }

    default:
      return state
  }
}

export default userListReducer
