import { Quotation } from '@interfaces/quotation'
import { QUOTATIONS_ACTION_TYPES } from '@services/redux/quotations/actions'

interface QuotationsState {
  data: Quotation[]
  error?: Error
  isFetching: boolean
}

const initialState: QuotationsState = {
  data: [],
  isFetching: false
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const quotationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUOTATIONS_ACTION_TYPES.LIST_QUOTATIONS:
      return {
        ...state,
        error: undefined,
        isFetching: true
      }

    case QUOTATIONS_ACTION_TYPES.LIST_QUOTATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.quotations
      }

    case QUOTATIONS_ACTION_TYPES.LIST_QUOTATIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }

    default:
      return state
  }
}

export default quotationsReducer
