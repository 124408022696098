import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import loginReducer from '@containers/login/reducers/login-reducer'
import customersReducer from '@services/redux/customers/reducer'
import lastWeekReportsReducer from '@services/redux/last-week-reports/reducer'
import projectsReducer from '@services/redux/projects/reducer'
import quotationsReducer from '@services/redux/quotations/reducer'
import reportTypesReducer from '@services/redux/report-types/reducer'
import reportsReducer from '@services/redux/reports/reducer'
import userListReducer from '@services/redux/users/reducer'

const loginPersistConfig = {
  key: 'user',
  storage,
  blacklist: ['errors']
}

const keyPersistConfig = (key: string) => ({
  key,
  storage
})

export const rootReducer = combineReducers({
  projects: persistReducer(keyPersistConfig('projects'), projectsReducer) as typeof projectsReducer,
  reports: persistReducer(keyPersistConfig('reports'), reportsReducer) as typeof reportsReducer,
  lastWeekReports: persistReducer(keyPersistConfig('lastWeekReports'), lastWeekReportsReducer) as typeof lastWeekReportsReducer,
  reportTypes: persistReducer(keyPersistConfig('reportTypes'), reportTypesReducer) as typeof reportTypesReducer,
  user: persistReducer(loginPersistConfig, loginReducer) as typeof loginReducer,
  users: persistReducer(keyPersistConfig('users'), userListReducer) as typeof userListReducer,
  customers: persistReducer(keyPersistConfig('customers'), customersReducer) as typeof customersReducer,
  quotations: persistReducer(keyPersistConfig('quotations'), quotationsReducer) as typeof quotationsReducer
})

export type RootState = ReturnType<typeof rootReducer>
