import moment from 'moment'
import React from 'react'

import { Header } from '@interfaces/header'

interface Props {
  headers: Header[]
}

const today = moment().format('MMM DD')

const Headers = ({ headers }: Props) => {
  const classNameHeadersManagement = (index, date) => {
    if (index > 10) {
      return 'sticky right-0 tilted-red px-4'
    } else if (index === 10) {
      return 'px-4 border-r'
    } else if (index === 0) {
      return 'px-12 border-r'
    } else if (index === 1) {
      return 'px-9 border-r'
    } else if (index === 2) {
      return 'px-2 border-r'
    } else if (today === date) {
      return 'bg-white text-red-800 border-r'
    } else {
      return 'border-r'
    }
  }

  return (
    <thead className='sticky top-0'>
      <tr className='sticky top-0 tilted-red'>
        {headers.map((item, index) => {
          return (
            <th className={`text-center text-white font-mono w-40 border-solid border-b sticky top-0 tracking-wider ${classNameHeadersManagement(index, item.header)}`} key={item.header}>
              {item?.day}

              {(item?.day) ? <br /> : null}

              {item.header}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default Headers
