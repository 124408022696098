/* eslint-disable @typescript-eslint/ban-types */

const toCamel = (str: string) => {
  let addUnderscore = false

  if (str[0] === '_') {
    addUnderscore = true
  }

  const camelCasedString = str
    .replace(/^[_]/, '')
    .replace(/([-_][a-z])/gi, $1 => {
      return $1
        .toUpperCase()
        .replace('-', '')
        .replace('_', '')
    })
    .replace(/^[A-Z]/, $1 => {
      return $1.toLowerCase()
    })

  return `${addUnderscore ? '_' : ''}${camelCasedString}`
}

const isObject = (obj: object): boolean => {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function'
}

const translateToOtherCase = (
  obj: object | object[],
  translator: (str: string) => string
): object | object[] => {
  if (isObject(obj)) {
    const n = {}

    Object.keys(obj).forEach(k => {
      n[translator(k)] = translateToOtherCase(obj[k], translator)
    })

    return n
  } else if (Array.isArray(obj)) {
    return obj.map(i => {
      return translateToOtherCase(i, translator)
    })
  }

  return obj
}

export const keysToCamel = (obj: object | object[]): object | object[] => {
  return translateToOtherCase(obj, toCamel)
}

/* eslint-enable @typescript-eslint/ban-types */
