import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'

import { Project } from '@interfaces/project'
import { Report } from '@interfaces/report'
import { RootState } from '@services/redux/config/reducers'
import { convertDuration } from '@services/tools/reports/formatter'

interface Props {
  currentWeekReports: Report[]
  dates: moment.Moment[]
  lastWeekReports: Report[]
}

const UserSummaryDetails = ({
  currentWeekReports,
  dates,
  lastWeekReports
}: Props) => {
  const { data: projects } = useSelector((state: RootState) => state.projects)

  const getDistinctArrayValues = (value, index, self) => self.indexOf(value) === index
  const projectIds = [
    ...lastWeekReports.map(i => i.project),
    ...currentWeekReports.map(i => i.project)
  ].filter(getDistinctArrayValues)

  const sum = (accumulator: number, a: number) => accumulator + a

  const getProjectDurationByDate = (reports: Report[], project: Project, date: moment.Moment) =>
    reports
      .filter(r =>
        r.project === project && r.date === date.format('YYYY-MM-DD')
      )?.map(r => r.duration).reduce(sum, 0) ?? 0

  return (
    <>
      {projectIds?.map(pId => (
        <tr>
          <td className='text-right text-xs border-solid border-r border-t mt-4 font-mono whitespace-nowrap bg-gray-100 border-white text-ellipsis px-2 sticky left-0'>
            {projects.find(p => p.id === pId).name}
          </td>

          {dates?.map((date, index) => {
            const day = date.format('ddd')
            const today = moment().format('MM-DD')
            return (
              <td
                className={`text-center border-solid border-t border-l mt-4 font-mono whitespace-nowrap bg-gray-100 border-white
                ${day === 'Sun' || day === 'Sat' ? 'bg-gray-200' : ''}
                ${today === date.format('MM-DD') ? 'bg-indigo-100 border-white' : ''}`}
              >
                {(index < 7)
                  ? convertDuration(getProjectDurationByDate(lastWeekReports, pId, date))
                  : convertDuration(getProjectDurationByDate(currentWeekReports, pId, date))
                }
              </td>
            )
          })}
        </tr>
      ))}
    </>
  )
}

export default UserSummaryDetails
