import moment from 'moment'
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'

import {
  LOGIN_TYPES,
  loginFailure,
  loginSuccess,
  loginUserActionType
} from '@containers/login/actions/login-actions'
import { apiCallWrapper } from '@services/api'
import { LOGIN_URL } from '@services/api/auth'
import { axiosPost, setAuthorizationToken } from '@services/api/axios'
import { listProjects } from '@services/redux/projects/actions'
import { listReportTypes } from '@services/redux/report-types/actions'
import { listReports } from '@services/redux/reports/actions'

function * logInWithCredentials ({ payload: { email, password } }: loginUserActionType) {
  try {
    const { data, error, success } = yield call(
      apiCallWrapper,
      axiosPost,
      LOGIN_URL,
      {
        identifier: email,
        password: password
      }
    )

    if (success) {
      const { jwt, user } = data
      const today = moment()
      const fromDate = today.clone().startOf('week')
      const toDate = today.endOf('week').add(2, 'days')
      const start = fromDate.format('YYYY-MM-DD')
      const end = toDate.format('YYYY-MM-DD')

      setAuthorizationToken(jwt)

      yield put(loginSuccess(user))

      yield put(listReportTypes())
      yield put(listProjects())
      yield put(listReports(start, end))
    } else {
      yield put(loginFailure(error))
    }
  } catch (error) {
    yield put(loginFailure(error))
  }
}

function * logout () {
  setAuthorizationToken()
}

export default function * loginSagas () {
  yield takeLatest(LOGIN_TYPES.LOGIN_USER, logInWithCredentials)
  yield takeLeading(LOGIN_TYPES.LOGOUT, logout)
}
