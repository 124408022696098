import { Report } from '@interfaces/report'
import { ReportLine } from '@interfaces/report-line'

export const formatReportLines = (reports: Report[]) => {
  const formattedReports: ReportLine[] = []

  reports.forEach(elem => {
    const finalArrayProjectIndex = formattedReports.findIndex((finalElem) => (
      finalElem.project.id === elem.project.id &&
        finalElem.reportType.id === elem.reportType.id &&
        finalElem.quotation?.id === elem.quotation?.id &&
        finalElem.reports.findIndex(x => x.date === elem.date)
    ))

    if (finalArrayProjectIndex !== -1) {
      formattedReports[finalArrayProjectIndex].reports.push(elem)
    } else {
      formattedReports.push({
        project: elem.project,
        reportType: elem.reportType,
        quotation: elem.quotation,
        reports: [elem]
      })
    }
  })

  return formattedReports
}

export const convertDuration = (num) => {
  if (num === 0) {
    return ('-')
  }
  const hours = (num / 60)
  const rhours = Math.floor(hours)
  const minutes = (hours - rhours) * 60
  const rminutes = Math.round(minutes)
  return (`${((rhours < 10) ? `0${rhours}` : rhours)}:${((rminutes < 10) ? `0${rminutes}` : rminutes)}`)
}
